import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[SVGPreRecordIcon]'
})
export class SVGPreRecordIconDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const svg = `
<svg
class="w-16 h-16 cursor-pointer"
(click)="prmyBtn()"
viewBox="0 0 100 100"
xmlns="http://www.w3.org/2000/svg"

>
<circle cx="50" cy="50" r="50" fill="#ffffff" />
<circle cx="50" cy="50" r="30" fill="#ff0000" />
</svg>
    `;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', svg);
  }
}




