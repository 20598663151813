// logging.service.ts
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export enum LogLevel {
  TRACE = 0,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  OFF
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private logLevel: LogLevel = LogLevel.TRACE;

  private mapLogLevel(level: string): LogLevel {
    switch (level.toUpperCase()) {  // Ensure level is uppercase for comparison
      case 'TRACE':
        return LogLevel.TRACE;
      case 'DEBUG':
        return LogLevel.DEBUG;
      case 'INFO':
        return LogLevel.INFO;
      case 'WARN':
        return LogLevel.WARN;
      case 'ERROR':
        return LogLevel.ERROR;
      case 'OFF':
        return LogLevel.OFF;
      default:
        return LogLevel.OFF;
    }
  }

  private log(level: LogLevel, message: string, ...args: any[]) {
    if (level >= this.logLevel) {
      const levelString = LogLevel[level].toLowerCase();
      const formattedMessage = `[${levelString}] - ${message}`;
      switch (level) {
        case LogLevel.TRACE:
          console.debug(formattedMessage, ...args);
          break;
        case LogLevel.DEBUG:
          console.debug(formattedMessage, ...args);
          break;
        case LogLevel.INFO:
          console.info(formattedMessage, ...args);
          break;
        case LogLevel.WARN:
          console.warn(formattedMessage, ...args);
          break;
        case LogLevel.ERROR:
          console.error(formattedMessage, ...args);
          break;
      }
    }
  }

  trace(message: string, ...args: any[]) {
    this.log(LogLevel.TRACE, message, ...args);
  }

  debug(message: string, ...args: any[]) {
    this.log(LogLevel.DEBUG, message, ...args);
  }

  info(message: string, ...args: any[]) {
    this.log(LogLevel.INFO, message, ...args);
  }

  warn(message: string, ...args: any[]) {
    this.log(LogLevel.WARN, message, ...args);
  }

  error(message: string, ...args: any[]) {
    this.log(LogLevel.ERROR, message, ...args);
  }
}
