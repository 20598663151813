import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  constructor(private ngZone: NgZone) {}

  onResize(element: HTMLElement): Observable<DOMRectReadOnly> {
    return new Observable(observer => {
      const resizeObserver = new ResizeObserver(entries => {
        this.ngZone.run(() => {
          for (let entry of entries) {
            observer.next(entry.contentRect);
          }
        });
      });

      resizeObserver.observe(element);

      return () => resizeObserver.unobserve(element);
    });
  }
}
