import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { LoaderComponent } from './loader.component';
import { ButtonComponent } from './button.component';
import {WebcamRecorderComponent} from './webcamRecorder.component';
import {viewFullTerms} from './legal/legal.component';


const components = [
  HeaderComponent,
  LoaderComponent,
  ButtonComponent,
  WebcamRecorderComponent,
  viewFullTerms
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule],
  exports: [...components],
})
export class SharedComponentsModule {}
