import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from '@yoyo/env';
import { ReactionService } from '@yoyo/services';

export class DataAccessException extends Error {
  message: string;
  private cause: unknown;

  constructor(message: string, cause: unknown) {
    super(message);
    this.cause = cause;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CloudStorageService {


  constructor() {}

  initFirebase() {

    if (!firebase.default.apps.length) {
 //     console.log('INITIALISING IN THE SERVICE');
      const fbConfig = environment.firebase;
      firebase.default.initializeApp(environment.firebase);
      
    }else {
//      console.log('INITIALISIED ELSEWHERE');
      firebase.default.app(); // if already initialized, use that one
    }
  }

  async upload_file(path: string, file: Blob) : Promise<firebase.default.storage.UploadTask>{
    try {
      const ref = firebase.default.storage().ref(`${path}`);
      var metadata = {
        contentType: 'video/webm',
      };
      return await ref.put(file, metadata);
    } catch (e) {
      throw new DataAccessException('cannot upload file',e );
    }
  }

  async retrive_file(path: string) {
    try {
      const ref = firebase.default.storage().ref(`${path}`);
      return await ref.getDownloadURL();
    } catch (e) {
      throw e;
    }
  }


}
