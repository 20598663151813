import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrimaryLayout } from '@yoyo/layouts';
import { CreateReactionComponent } from '@yoyo/pages';

const routes: Routes = [
  {
    path: 'v2',
    component: PrimaryLayout,
    children: [
      {
        path: 'faultLocal',
        component: CreateReactionComponent,
      },
      {
        path: ':id',
        component: CreateReactionComponent,
      },
      {
        path: '',
        redirectTo: 'faultLocal', // Redirect to faultLocal if no ID is provided
        pathMatch: 'full'
      },
    ],
  },
  { path: '', redirectTo: 'v2', pathMatch: 'full' }, // Redirect root to 'v2'
  { path: '**', redirectTo: 'v2', pathMatch: 'full' }, // Catch-all for undefined routes
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
