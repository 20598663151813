import { Injectable } from '@angular/core';
import { StepV3, AppHost, valueLabel, AppHostTypes } from '@yoyo/types';
import { HostStateService, AppStateService, LoggingService } from '@yoyo/services';
import { environment } from '@yoyo/env';

@Injectable({
  providedIn: 'root',
})
export class getStepData {
  current_host_config: AppHost;
  host_config: AppHost;
  stepArray: string[];
  appScope: AppHostTypes;

  constructor(
    private hs: HostStateService,
    private app_state: AppStateService,
    private logger: LoggingService
  ) {
    this.logger.debug('getStepData constructor called');
    this.host_config = this.hs.currentHostConfig;
    this.appScope = this.app_state.appScope;
  }

  themeStepData(): StepV3 {
    this.logger.trace('Fetching theme step data');
    const themeStep: StepV3 = {
      state: {
        base: {
          uiElement: {
            img: '/assets/images/webWave.png',
            heading: 'Heading',
            body: 'Body',
            info: 'Info',
            overlay1: 'overlay1',
            overlay2: '',
            overlay3: '',
            prmy_btn: 'prmy_btn',
            scnd_btn: 'scnd_btn',
            thrd_btn: 'thrd_btn',
          },
        },
      },
    };
    return themeStep;
  }

  cloudStepDataFromComponentName(component: string): StepV3 {
    this.logger.trace('Fetching cloud step data for component: ' + component);
    const stepKey = component;
    let stepData: StepV3;

    try {
      if (this.app_state.appScope === 'getApp') {
        stepData = this.host_config.app_content[this.app_state.appScope].step[
          stepKey as keyof typeof this.host_config.app_content.getApp.step
        ] as StepV3;
      } else if (this.app_state.appScope === 'replyApp') {
        stepData = this.host_config.app_content[this.app_state.appScope].step[
          stepKey as keyof typeof this.host_config.app_content.replyApp.step
        ] as StepV3;
      }
      this.logger.debug('Pulled config: ' + JSON.stringify(stepData, null, 2));
    } catch (err) {
      this.logger.error('Getting step data was a problem. ' + err);
    }
    return stepData;
  }

  setStepArray(stepsArray: string[]) {
    this.logger.trace('Setting step array');
    this.stepArray = [...stepsArray];
    this.logger.debug('stepArray is set to: ' + JSON.stringify(this.stepArray, null, 2));
  }

  getStepNumber(componentName: string): number {
    this.logger.trace('Getting step number for component: ' + componentName);
    let returnStep = -1;

    if (!this.stepArray) {
      this.logger.error('The step list is null and that is terminal');
      returnStep = -1;
    } else if (this.stepArray.length === 0) {
      this.logger.error('The step list is empty and that is terminal');
      returnStep = -1;
    } else {
      const gotoStep = this.stepArray.indexOf(componentName);
      if (gotoStep === -1) {
        this.logger.error('The passed in destination: ' + componentName + ' was not found. Terminal');
        returnStep = -1;
      } else {
        this.logger.debug('Found the step and it is: ' + gotoStep);
        returnStep = gotoStep;
      }
    }

    if (returnStep === -1) {
      return environment.editor ? -2 : -1;
    } else {
      return returnStep;
    }
  }

  getListSteps(maxNumSteps?: number): valueLabel[] {
    this.logger.trace('Getting list of steps');
    const steps: valueLabel[] = [];

    if (!this.stepArray || this.stepArray.length === 0) {
      this.logger.warn('stepArray is undefined or uninitialized. Using mock steps');
      return this.mockStepList();
    }

    if (!maxNumSteps) {
      maxNumSteps = this.stepArray.length;
    }

    for (let i = 0; i < maxNumSteps; i++) {
      if (this.stepArray[i] && this.stepArray[i].trim() !== '') {
        steps.push({
          value: this.stepArray[i],
          label: `${i} - ${this.stepArray[i]}`,
        });
      } else {
        steps.push({
          value: this.stepArray[i],
          label: '<not set>',
        });
      }
    }

    if (steps.length === 0) {
      this.logger.warn('Using mock steps');
      return this.mockStepList();
    } else {
      return steps;
    }
  }

  mockStepList(maxNumSteps?: number): valueLabel[] {
    this.logger.trace('Creating mock step list');
    return [
      { value: '0', label: '0 - ReactionSetupStep' },
      { value: '1', label: '1 - ReactionModeStep' },
      { value: '2', label: '2 - ReactionWatchStep' },
      { value: '3', label: '3 - ReactionPermissionRequestStep' },
      { value: '4', label: '4 - ReactionPermissionDeniedStep' },
      { value: '5', label: '5 - ReactionRecordStep' },
      { value: '6', label: '6 - ReactionResponseOptionsStep' },
      { value: '7', label: '7 - ReactionRecordPostResponseStep' },
      { value: '8', label: '8 - ReactionWriteResponseStep' },
      { value: '9', label: '9 - ReactionOfferStep' },
      { value: '10', label: '10 - RecordReviewStep' },
      { value: '11', label: '11 - ReactionSlowStep' },
      { value: '12', label: '12 - ReactionSimpleShowStep' },
      { value: '13', label: '13 - ReactionLegalStep' },
      { value: '14', label: '14 - ReactionRetryFaultStep' },
      { value: '15', label: '15 - ReactionConfigfailStep' },
      { value: '16', label: '16 - v2imgLandStep' },
      { value: '17', label: '17 - v2VidLandStep' },
      { value: '18', label: '18 - v2postVideoStep' },
      { value: '19', label: '19 - v2postMsgStep' },
      { value: '20', label: '20 - v2theme' },
    ];
  }

  mockStepData(stepNumber: number): StepV3 {
    this.logger.trace('Fetching mock step data for step number: ' + stepNumber);

    if (stepNumber === -1) {
      const faultData: StepV3 = {
        _module: 'exampleModule',
        state: {
          base: {
            uiElement: {
              img: '/assets/images/webWave.png',
              heading: 'Data fault',
              body: 'An error has occurred because the host setup is broken. This can only be fixed by an administrator',
              info: 'Press send to let us know',
              overlay1: 'Boom crash bang',
              overlay2: '',
              overlay3: '',
              prmy_btn: 'Send',
              scnd_btn: '',
              thrd_btn: '',
            },
            uiAction: {
              backendAction: {
                img: '',
                heading: '',
                body: '',
                info: '',
                overlay1: '',
                overlay2: '',
                overlay3: '',
                prmy_btn: 'Step data fault',
                scnd_btn: '',
                thrd_btn: '',
              },
            },
          },
        },
      };
      return faultData;
    }

    if (stepNumber === -2) {
      return {} as StepV3;
    }

    return null;
  }
}
