import { Injectable, inject } from '@angular/core';
import { LoggingService } from '@yoyo/services';

@Injectable({
  providedIn: 'root'
})
export class VideoPreloadService {
  private logger = inject(LoggingService);

  preloadVideo(url: string): Promise<void> {
    this.logger.info('preloadVideo triggered', 'video-preload.service', '@1');
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = url;
      video.preload = 'auto';
      video.muted = true;  // Ensure the video is muted to prevent autoplay issues

      video.addEventListener('canplaythrough', () => {
        this.logger.info(`Video can play through: ${url}`, 'video-preload.service', '@2');
        resolve();
      });

      video.addEventListener('error', (event) => {
        let errorMessage = 'An unknown error occurred.';
        if (video.error) {
          switch (video.error.code) {
            case MediaError.MEDIA_ERR_ABORTED:
              errorMessage = 'The video loading was aborted.';
              break;
            case MediaError.MEDIA_ERR_NETWORK:
              errorMessage = 'A network error caused the video download to fail.';
              break;
            case MediaError.MEDIA_ERR_DECODE:
              errorMessage = 'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.';
              break;
            case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
              errorMessage = 'The video could not be loaded, either because the server or network failed or because the format is not supported.';
              break;
            default:
              errorMessage = 'An unknown error occurred.';
              break;
          }
        }
        const error = new Error(`Error preloading video: ${url} - ${errorMessage}`);
        error.name = 'VideoPreloadError';
        
        this.logger.error(`Video preloading failed: ${url} - ${errorMessage}`, 'video-preload.service', '@3');
        reject(error);
      });

      this.logger.info(`Loading video: ${url}`, 'video-preload.service', '@4');
      video.load();
    });
  }
}
