import {HttpClient} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {environment} from '@yoyo/env';
import {DetectionObject, Reaction} from '@yoyo/types';
import {AppStateService,CloudStorageService, HostStateService, AuthService, LoggingService, MessageCreationService} from '@yoyo/services';
import {makeId} from '@yoyo/shared';
import {Observable, throwError } from 'rxjs';
import {catchError, concatMap, map, tap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class ReactionService {
    private logger = inject(LoggingService);
    private commsManager = inject(MessageCreationService);

    reactionValid: boolean = false;
    reaction_recording_blob: Blob;
    reaction_video_id: string;
    reaction: Reaction;
    reaction_detections: DetectionObject[];
    private fileName: string;

    constructor(
        private http: HttpClient,
        private app_state: AppStateService,
        private fs: CloudStorageService,
        private host_state: HostStateService,
        private authService: AuthService
    ) {
        this.fs.initFirebase();
    }

    getReactionData(reactionId: string): Observable<{ reaction: Reaction }> {
        this.logger.info('Attempting to retrieve reaction data for reactionId: ' + reactionId);
    
        try {
            const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
            const host = this.host_state.currentHostName;
    
            return this.http
                .post<any>(
                    environment.api.reaction.get,
                    { 
                        reactionId,
                        'host': host
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: authToken,
                        },
                        responseType: 'json',
                    }
                )
                .pipe(
                    map(response => {
                        this.logger.trace('getReactionData full response: ' + JSON.stringify(response, null, 2));
                        if (response.payload && response.payload.reaction && response.payload.reaction.data) {
                            this.logger.debug('Successfully retrieved reaction data: ' + JSON.stringify(response.payload.reaction.data, null, 2));
                            // Return the data field as the reaction type
                            const idCorrectedOutput: Reaction = this.checkReactionIds(response.payload.reaction.data as Reaction);
                            return { reaction: idCorrectedOutput };
                        } else {
                            this.logger.error('Invalid response structure for reactionId: ' + reactionId);
                            this.logger.debug('Received response: ' + JSON.stringify(response, null, 2));
                            throw new Error('Invalid response structure');
                        }
                    }),
                    catchError(error => {
                        this.logger.warn('Failed to retrieve reaction data for reactionId: ' + reactionId);
                        this.logger.error('Error details: ', error);
                        return throwError(error);
                    })
                );
        } catch (e) {
            this.logger.error('An error occurred while processing reactionId: ' + reactionId, e);
            throw e;
        }
    }
    

    checkReactionIds(input: Reaction): Reaction {
        this.logger.info('checkReactionIds triggered ', 'reaction.service','@4');
        if (!input.id) {
            this.logger.info('checkReactionIds no id adding ', 'reaction.service','@4.1');
            input.id = input.reactionId;
        }
        if (!input.reactionId) {
            this.logger.info('checkReactionIds no reactionId ', 'reaction.service','@4.1');
            input.reactionId = input.id;
        }
        return input;
    }
    
    
    async logAccess(reactionId: string, appScope: string) {
        this.logger.info(`Attempting to log access for reactionId: ${reactionId} with appScope: ${appScope}`);
    
        try {
            const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
            const host = this.host_state.currentHostName;
    
            // Log the parameters before making the HTTP call
            this.logger.debug(`Sending request to log access with host: ${host}, reactionId: ${reactionId}, appScope: ${appScope}`);
    
            const response = await this.http
                .post<{ reaction: Reaction }>(
                    environment.api.reaction.logAccess,
                    {
                        reactionId,
                        app: appScope,
                        host: host
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: authToken,
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
    
            // Log the successful response
            this.logger.debug(`Successfully logged access for reactionId: ${reactionId}. Response: ${JSON.stringify(response, null, 2)}`);
            return response;
        } catch (e) {
            // Log the error details for better traceability
            this.logger.error(`Failed to log access for reactionId: ${reactionId} with appScope: ${appScope}. Error details: `, e);
            throw e;
        }
    }
    

    async actionTrigger(url: string): Promise<void> {
        this.logger.info(`Action trigger initiated for url: ${url}`);
        
        const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
    
        // Log the request details
        this.logger.debug(`Sending action trigger request for reactionId: ${this.app_state.current_reaction.reactionId}, host: ${this.host_state.currentHostConfig.name}, targetURL: ${url}`);
    
        const endpoint = environment.api.reaction.actionTrigger;
        this.logger.trace('actionTrigger endpoint being used is: ' + endpoint, 'reaction.service', '@1');
    
        try {
            await this.http
                .post<any>(
                    environment.api.reaction.actionTrigger,
                    {
                        reactionId: this.app_state.current_reaction.reactionId,
                        host: this.host_state.currentHostConfig.name,
                        url: url
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': authToken
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
    
            // Log success
            this.logger.info(`Action successfully triggered for url: ${url}`);
        } catch (error) {
            // Log the error details
            this.logger.error('The action did not post correctly for url: ' + url, error);
            return;
        }
    }

    async upload(type: string) {
        this.logger.info('Upload triggered', 'reaction.service', '@3');
        
        try {
            const result = await this.uploadWebcamRecording('completed_reaction_video_ids');
            
            if (result) {
                this.logger.info('Upload completed successfully', 'reaction.service', '@3.1');
            } else {
                this.logger.warn('Upload result is not successful, but no further action is taken here.', 'reaction.service', '@3.2');
            }
        } catch (error: any) {
            this.logger.error(`Upload failed with error: ${JSON.stringify(error, null, 2)}`, 'reaction.service', '@3.3');
        }
    }
    

    async uploadWebcamRecording(field: string): Promise<boolean> {
        this.logger.info(`Starting uploadWebcamRecording with field: ${field}`, 'reaction.service', '@11');
    
        try {
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hour = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
    
            if (!this.reaction_video_id) {
                this.reaction_video_id = this.app_state.current_reaction.reactionId;
            }
    
            //this.fileName = `${this.reaction_video_id}_${year}${month}${day}_${hour}${minutes}${seconds}`;
            this.fileName = this.reaction_video_id;
            const fileExt = this.getFileExtensionFromBlob(this.reaction_recording_blob);
            this.fileName = `${this.fileName}.${fileExt}`;
    
            this.logger.debug(`Created filename: ${this.fileName}`, 'reaction.service', '@11.1');
            this.app_state.videoId = this.fileName;
            const host = this.host_state.currentHostName;
            const videoLoc = `${host}/reactions/${this.fileName}`;
    
            this.logger.debug(`Uploading to: ${videoLoc}`, 'reaction.service', '@11.2');
    
            // Upload the file
            await this.fs.upload_file(videoLoc, this.reaction_recording_blob);
            this.logger.info('File upload successful', 'reaction.service', '@11.3');

            if (fileExt !== 'mp4') {
                this.logger.info('File is not MP4, adding to conversion queue', 'reaction.service', '@11.4');
                this.saveVideoLoc(videoLoc, false);
                try {
                    const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
                    const endpoint = environment.api.queue.addTo_q_video;
                    this.logger.debug(`Targeting endpoint: ${endpoint}`, 'reaction.service', '@11.5');
    
                    // Add to conversion queue
                    await this.http
                        .post(
                            endpoint,
                            {
                                sourcePath: videoLoc,
                                clientId: this.host_state.currentHostName,
                                reactionId: this.reaction_video_id,
                                source: 'receiver',
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': authToken,
                                },
                                responseType: 'json',
                            }
                        )
                        .toPromise();
    
                } catch (error: any) {
                    this.logger.error(
                        `Error adding to conversion queue: ${JSON.stringify(error, null, 2)}`, 
                        'reaction.service', 
                        '@11.6'
                    );
                    throw error; // Continue re-throwing error to the main catch
                    return false;
                }
                return true;
            } else {
                this.saveVideoLoc(videoLoc, true);
                this.logger.debug('File is already MP4, no further action required', 'reaction.service', '@11.7');
                return true;
            }
    
        } catch (e: any) {
            this.logger.error(`Error in uploadWebcamRecording: ${e.message || JSON.stringify(e)}`, 'reaction.service', '@11.8');
            return false; // Signal failure when an error occurs
        }
    }

    async saveVideoLoc(location: string, converted: boolean): Promise<void> {
        this.logger.info('saveVideoLoc triggered', 'reaction.service', '@10');
        const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
    
        try {
            const endpoint = environment.api.reaction.update;
            this.logger.debug(`Targeting endpoint: ${endpoint}`, 'reaction.service', '@10.1');
    
            this.http
                .put<boolean>(
                    endpoint,
                    {
                        reaction_id: this.app_state.current_reaction.id,
                        host: this.host_state.currentHostName,
                        field: 'response',
                        data: { "type": "video", "msg": "", "video": location, "converted": converted }
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': authToken,
                        },
                        responseType: 'json',
                    }
                )
                .toPromise()
                .catch((e) => this.logger.error('Error in saveVideoLoc: ' + JSON.stringify(e), 'reaction.service', '@10.2'));
        } catch (e) {
            this.logger.error('Error in synchronous part of saveVideoLoc: ' + JSON.stringify(e), 'reaction.service', '@10.2');
            throw e;
        }
    
        const emailSubmission = this.commsManager.createEvaluatePayload(converted);
        
        try {
            const endpoint = environment.api.queue.addTo_q_email;
            this.logger.debug(`Targeting endpoint: ${endpoint}`, 'reaction.service', '@10.3');
    
            this.http
                .put<boolean>(
                    endpoint,
                    {
                        reactionId: this.app_state.current_reaction.id,
                        clientId: this.host_state.currentHostName,
                        emailAttributes: emailSubmission
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': authToken,
                        },
                        responseType: 'json',
                    }
                )
                .toPromise()
                .catch((e) => this.logger.error('Error in email submission: ' + JSON.stringify(e), 'reaction.service', '@10.4'));
        } catch (e) {
            this.logger.error('Error in synchronous part of email submission: ' + JSON.stringify(e), 'reaction.service', '@10.4');
            throw e;
        }
    }
    


    async saveMessage(message: string): Promise<void> {
        this.logger.info('Saving message', 'reaction.service', '@9');
        const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
    
        try {
            const endpoint = environment.api.reaction.update;
            this.logger.debug(`Saving message targeting endpoint: ${endpoint}`, 'reaction.service', '@9.1');
    
            this.http
                .put<boolean>(
                    endpoint,
                    {
                        reaction_id: this.app_state.current_reaction.id,
                        host: this.host_state.currentHostName,
                        field: 'response',
                        data: { "type": "msg", "msg": message, "video": "", "converted": false }
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': authToken,
                        },
                        responseType: 'json',
                    }
                )
                .toPromise()
                .catch((e) => this.logger.error('Error in saveMessage: ' + JSON.stringify(e), 'reaction.service', '@9.2'));
        } catch (e) {
            this.logger.error('Error in synchronous part of saveMessage: ' + JSON.stringify(e), 'reaction.service', '@9.2');
            throw e;
        }
    
        const emailSubmission = this.commsManager.createEvaluatePayload(false);
        
        try {
            const endpoint = environment.api.queue.addTo_q_email;
            this.http
                .put<boolean>(
                    endpoint,
                    {
                        reactionId: this.app_state.current_reaction.id,
                        clientId: this.host_state.currentHostName,
                        emailAttributes: emailSubmission
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': authToken,
                        },
                        responseType: 'json',
                    }
                )
                .toPromise()
                .catch((e) => this.logger.error('Error in email submission: ' + JSON.stringify(e), 'reaction.service', '@9.3'));
        } catch (e) {
            this.logger.error('Error in synchronous part of email submission: ' + JSON.stringify(e), 'reaction.service', '@9.3');
            throw e;
        }
    }
    
    
    getFileExtensionFromBlob(blob: Blob): string {
        const mimeType = blob.type;
        this.logger.info('getFileExtensionFromBlob triigerde and mime type is: ' + mimeType, 'reaction.service', '_!@18');
        // Determine the file extension based on the MIME type
        let extension = '';
        switch (mimeType) {
            case 'video/webm':
                extension = 'webm';
                break;
            case 'video/mp4':
                extension = 'mp4';
                break;
            case 'video/ogg':
                extension = 'ogg';
                break;
            case 'video/x-matroska':
                extension = 'mkv';
                break;
            default:
           //     console.warn('Unknown MIME type:', mimeType);
                extension = ''; // Default or unknown case
                break;
        }
    
        return extension;
    }

    async updateLegalAgreement(reaction_id: string): Promise<void> {
        this.logger.info('Updating legal agreement for reaction_id: ' + reaction_id, 'reaction.service', '_!@28');
        try {
            await this.http
                .post<{}>(
                    environment.api.reaction.agree,
                    {
                        reaction_id,
                        host: this.host_state.currentHostName,
                        date_time: new Date().toLocaleString(),
                    },
                    {
                        headers: {
                            ['Content-Type']: 'application/json',
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
        } catch (e) {
            this.logger.error('Error in updateLegalAgreement: ' + JSON.stringify(e), 'reaction.service', '_!@29');
            throw e;
        }
    }



    /*
    recordFileSent(type: string): Observable<boolean> {
        this.logger.info('Recording file sent with type: ' + type, 'reaction.service', '_!@18');
        const authToken = 'Bearer ' + this.authService.getAuthServiceToken();
        return this.http
            .put<boolean>(
                environment.api.reaction.update,
                {
                    reaction_id: this.app_state.current_reaction.id,
                    field: 'response',
                    host: this.host_state.currentHostName,
                    data: {"type":type,"msg":"","video":this.fileName}
                },
                {
                    headers: {
                        ['Content-Type']: 'application/json',
                        Authorization: authToken,
                    },
                    responseType: 'json',
                }
            ).pipe(
                catchError(error => {
                    this.logger.error('Error in recordFileSent: ' + JSON.stringify(error), 'reaction.service', '_!@19');
                    return throwError(error);
                })
            );
    }
*/

/*
    async retriveWebcamRecording(): Promise<string> {
        try {
            console.log('Retriving File');
            // Retreive the user recording video from cloud storage
            const videoDownloadURL = await this.fs.retrive_file(
                `reaction_recordings/${this.app_state.current_reaction.reaction_output_id}.mp4`
            );
            return videoDownloadURL;
        } catch (e) {
            throw e;
        }
    }

    async retriveCompletedReaction(): Promise<string> {
        try {
            // Retreive the reaction URL from cloud storage.
            const videoDownloadURL = await this.fs.retrive_file(
                `reactions/${this.app_state.current_reaction.reaction_output_id}.mp4`
            );
            return videoDownloadURL;
        } catch (e) {
            throw e;
        }
    }

*/


    /*
    async initReactionCreation(): Promise<void> {
        try {
            const recording_detections =
                !!this.reaction_detections && !!this.reaction_detections.length
                    ? this.reaction_detections
                    : [];
            await this.http
                .post<{ data: Reaction }>(
                    environment.api.reaction.create,
                    {
                        reactionId: this.app_state.current_reaction.reaction_output_id,
                        reactionDocId: this.app_state.current_reaction.id,
                        reactionDetections: recording_detections,
                    },
                    {
                        headers: {
                            ['Content-Type']: 'application/json',
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
        } catch (e) {
            throw e;
        }
    }
        */


    
/*
    async emailCompletedReaction(): Promise<void> {
        try {
            console.log('Sending with HostID', this.host_state.currentHostConfig.id);
            await this.http
                .post<{ data: Reaction }>(
                    environment.api.reaction.send,
                    {
                        reactionId: this.app_state.current_reaction.reaction_output_id,
                        reaction_doc_id: this.app_state.current_reaction.id,
                        senderEmail:
                        this.app_state.current_reaction.sender_details.email_address,
                        receiverEmail:
                        this.app_state.current_reaction.receiver_details.email_address,
                        hostId: this.host_state.currentHostConfig.id,
                    },
                    {
                        headers: {
                            ['Content-Type']: 'application/json',
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
        } catch (e) {
            throw e;
        }
    }
*/

    
    /*
    async updateResponse(reaction_id: string): Promise<void> {
        this.logger.info('Updating legal agreement for reaction_id: ' + reaction_id, 'reaction.service', '_!@28');
        try {
            await this.http
                .post<{}>(
                    environment.api.reaction.agree,
                    {
                        reaction_id,
                        host: this.host_state.currentHostName,
                        date_time: new Date().toLocaleString(),
                    },
                    {
                        headers: {
                            ['Content-Type']: 'application/json',
                        },
                        responseType: 'json',
                    }
                )
                .toPromise();
        } catch (e) {
            this.logger.error('Error in updateLegalAgreement: ' + JSON.stringify(e), 'reaction.service', '_!@29');
            throw e;
        }
    }
*/

/*
    async convertFinalVideo(): Promise<void> {
        if (this.app_state.videoId === null) {
            console.log('Do not have video_id.');
        } else {
            console.log('video convert request being made.');
            const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
            try {
                const reaction_id = this.app_state.current_reaction.id;
                const video_id = this.app_state.videoId;
                await this.http
                    .post<{}>(
                        environment.api.reaction.convert,
                        {
                            reaction_id,
                            video_id,
                            date_time: new Date().toLocaleString(),
                        },
                        {
                            headers: {
                            'Content-Type': 'application/json',
                            'Authorization' : authToken,
                            },
                            responseType: 'json',
                        }
                    )
                    .toPromise();
            } catch (e) {
                throw e;
            }
        }
    }
    */


 
    
    



    initiliseBlankReaction(){
        //Here to support copy over from editor
    }



}
