
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppHost } from '@yoyo/types';
import { environment } from '@yoyo/env';
import { AuthService, LoggingService } from '@yoyo/services';
import { StringifyOptions } from 'querystring';

@Injectable({
    providedIn: 'root',
  })

  export class hostCloudManagement {
    private logger = inject(LoggingService);
  
  constructor(private http: HttpClient,
              private authService: AuthService
          ) {}

   
    async retrieveHost(hostName: string, collection: string) {
        this.logger.info('retrieveHost triggered', 'hostCloudManage.service', '@1');
        this.logger.info('retrieveHost hostName: ' + hostName + ' collection ' + collection, 'hostCloudManage.service', '@1.1');
        try {
        const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
        const endpoint = environment.api.host.retrieveHost;
        this.logger.debug('retrieveHost targetting endpoint: ' + endpoint, 'hostCloudManage.service', '@1.2');
        const response = await this.http
            .post<any>(
            endpoint,
            { collection: collection,
                host_name: hostName
            },
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization' : authToken,
                },
                responseType: 'json',
            }
            )
            .toPromise();        
        return response;
        } catch (e) {
  //      console.log('error is: ' + JSON.stringify(e, null, 2));
        throw e
      }
    }


  }

