import { Pipe, PipeTransform } from '@angular/core';
import { Reaction } from '@yoyo/types';

@Pipe({ name: 'replaceHostVariable' })
export class ReplaceHostVariablePipe implements PipeTransform {
  transform(inputString: string | undefined, reactionData: Reaction): string {
    if (!inputString) {
      return '';
    }

    const formatDate = (dateInput: any): string => {
      let date: Date;

      if (dateInput && dateInput._seconds !== undefined && dateInput._nanoseconds !== undefined) {
        // Firestore timestamp
        date = new Date(dateInput._seconds * 1000 + dateInput._nanoseconds / 1000000);
      } else {
        // Assume it's a standard JavaScript date string or ISO 8601 date string
        date = new Date(dateInput);
      }

      if (isNaN(date.getTime())) {
        // dateInput is not a valid date
        return '';
      }

      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
      }).format(date);
    };

    return inputString
      .replace('{{sender.first_name}}', reactionData?.sender_details?.first_name || '')
      .replace('{{sender.last_name}}', reactionData?.sender_details?.last_name || '')
      .replace('{{receiver.first_name}}', reactionData?.receiver_details?.first_name || '')
      .replace('{{gift_image}}', reactionData?.product?.product_details?.image_src || '')
      .replace('{{gift_description}}', reactionData?.product?.product_details?.product_description || '')
      .replace('{{gift_message}}', reactionData?.gift_message || '')
      .replace('{{gift_name}}', reactionData?.product?.product_details?.label || '')
      .replace('{{receiver_message}}', reactionData?.response?.msg || '')
      .replace('{{last_accessed}}', formatDate(reactionData?.metaData?.getApp?.last_accessed_at))
      .replace('{{gift_landing}}', reactionData?.content?.imageURL || '');
  }
}
