import {Injectable, inject} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as firebase from 'firebase/app';
import { environment } from '@yoyo/env';
import { CloudStorageService, LoggingService } from '@yoyo/services';


@Injectable({
    providedIn: 'root'
  })

export class AuthService {
    private logger = inject(LoggingService);
    private authToken: any;
    private authServiceToken: any;
    constructor(private http: HttpClient, private fs: CloudStorageService){ this.fs.initFirebase();}

    async getToken(): Promise<void> {
        try {
            this.logger.info('Attmepting login', 'auth.service', '@1');
            await firebase.default.auth().signInWithEmailAndPassword(environment.account.email,environment.account.password)
            this.logger.info('login completed.  Setting auth token', 'auth.service', '@1.1');
            const authToken = await firebase.default.auth().currentUser.getIdToken()
            this.authServiceToken = authToken;
            this.authToken = authToken;
            this.logger.info('Auth token all set', 'auth.service', '@1.2');
        } catch (error) {
            this.logger.error('Authorisation failed', 'auth.service', '@1.3');
        }
    }

    getAuthToken(): string {
        return this.authToken;
      }

    getAuthServiceToken(): string {
        return this.authServiceToken;
    }
}