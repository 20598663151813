import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { HostStateService,  AppStateService, TelemetryService, AuthService, ReactionService, FaultResponseData, LoggingService, LogLevel } from '@yoyo/services';
import { environment } from '@yoyo/env';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private logger = inject(LoggingService);

  isResolvingAppState = true;
  reactionId: string | null = null;
  ready1: string = null;
  ready2: string = null;
  phoneNum: string;
  securitySetting = false     //controls when settings are known
  securityCheck = true;       //Checks are true
  securityValid = false;      //checks are passed
  entryFailed = false;        //entry attempted and failed
  recipentName: string = ''
  bottomMsg: string = ''
  urlDerviedHost: string = '';
  urlDerviedRection: string = '';
  line1: string = '';
  line2: string = '';
  line3: string = '';

  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    private hs: HostStateService,
    private rs: ReactionService,
    private app_state: AppStateService,
    private telemetry: TelemetryService,
    private auth: AuthService,
    private FaultResponseData: FaultResponseData
    ) {
      //const logLevel: LogLevel = LogLevel[environment.logging.level as keyof typeof LogLevel];
      const logLevel: LogLevel = LogLevel['DEBUG'];
      //this.logger.setLogLevel(logLevel);
    }
    
    async ngOnInit() {
      this.logger.info('Triggered ngOnInit', 'app.components', '@1');
  
      // Log the app scope and document location for debugging
      this.logger.debug('App component init. app scope is: ' + this.app_state.appScope, 'app.components', '@1.1');
      this.logger.warn('document.location.href: ' + document.location.href, 'app.components', '@1.2');
  
      // Initialize telemetry and log the actions
      this.telemetry.initialise();
      this.logger.trace('Telemetry initialized', 'app.components', '@1.3');
  
      this.telemetry.setInitialTime();
      this.logger.trace('Initial time set in telemetry', 'app.components', '@1.4');
  
      this.telemetry.startRecordingLoad();
      this.logger.trace('Telemetry load recording started', 'app.components', '@1.5');
  
      // Subscribe to router events and initialize app on NavigationEnd
      this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe(() => {
              this.logger.trace('NavigationEnd event triggered app initialization', 'app.components', '@1.6');
              this.initialiseApp();
          });
  }
  

  async initialiseApp() {
    this.logger.info('Triggered initialiseApp', 'app.components', '@2');

    try {
        this.urlDerviedHost = document.location.hostname;
        this.logger.trace('Derived host from URL: ' + this.urlDerviedHost, 'app.components', '@2.1');

        const parts = this.urlDerviedHost.split('.');
        parts.forEach((part, index) => {
            this.logger.trace('Processing URL part: ' + part, 'app.components', '@2.2');

            if (part === 'localhost') {
                this.localHostAppCheckAndSet('You are running on localhost. Ok to use giftApp, cancel for replyApp');
            } else {
                if (index === 0) {
                    this.urlDerviedHost = part;
                    this.logger.trace('Set derived host to: ' + this.urlDerviedHost, 'app.components', '@2.3');
                } else {
                    if (part === 'view') {
                        this.app_state.appScope = 'getApp';
                        this.logger.trace('App scope set to: getApp', 'app.components', '@2.4');
                    }
                    if (part === 'reply') {
                        this.app_state.appScope = 'replyApp';
                        this.logger.trace('App scope set to: replyApp', 'app.components', '@2.5');
                    }
                }
            }
        });

        if (this.app_state.appScope !== 'getApp' && this.app_state.appScope !== 'replyApp') {
            this.app_state.appScope = 'replyApp';
            this.logger.trace('Defaulting app scope to: replyApp to process.  Going to fault', 'app.components', '@2.6');
            //this.initialiseMessaging();
            this.faultReactionInit();
            return;
        }

        this.initialiseMessaging();
        this.urlDerviedRection = this.getLastChildRouteParam('id');
        this.logger.trace('Derived reaction ID: ' + this.urlDerviedRection, 'app.components', '@2.7');

        if (!this.urlDerviedRection) {
            this.logger.warn('No reaction ID determined, this is a fault', 'app.components', '@2.8');
            this.logger.trace('Document location: going to fault' + document.location.href, 'app.components', '@2.9');
            await this.faultReactionInit();
            return;
        }

        await this.auth.getToken();
        await Promise.all([this.hostInitialise(), this.reactionInitialise()]);
        this.telemetry.stopRecordingLoad();
        await this.telemetry.postTelemetry();

        if (this.securityValid || !this.securityCheck) {
            this.logger.trace('Host initialization completed', 'app.components', '@2.10');
            this.isResolvingAppState = false;
        }
        this.logger.trace('Both initialization tasks completed', 'app.components', '@2.11');

    } catch (e) {
        this.logger.error('Critical Error during initialisation', e, 'app.components', '@2.12');
        this.faultReactionInit();
        return;
    }
}

  
  localHostAppCheckAndSet(message: string){
    const userConfirmed = confirm(message);
    if (userConfirmed) {
      console.log('User clicked OK');
     this.app_state.appScope = "getApp"
    } else {
      console.log('User clicked Cancel');
      this.app_state.appScope = "replyApp"
    }
  }

    initialiseMessaging(){
      if(this.app_state.appScope === "getApp") {
        this.line1 = 'Securing the package: ';
        this.line2 = 'Assigning delivery:  ';
        this.line3 = 'Readying unwrap: ';
      } else {
        this.line1 = 'Checking the letterbox: ';
        this.line2 = 'Looking for thank-yous:  ';
        this.line3 = 'Getting the reply:  ';
      }
    }


    async hostInitialise() {
      this.logger.info('Triggered hostInitialise', 'app.components', '@3');
  
      try {
          const host = this.urlDerviedHost;
          this.logger.trace('Derived host: ' + host, 'app.components', '@3.1');
  
          this.hs.setHostName(host);
          this.logger.trace('Set host name to: ' + host, 'app.components', '@3.2');
  
          const hostConfig = await this.hs.getHostDataFromCloud(host, 'hosts');
          this.logger.trace('Received host configuration', 'app.components', '@3.3');
  
          if (hostConfig) {
              this.hs.applyConfiguration(hostConfig);
              this.ready2 = '✓';
              this.logger.trace('Host configuration applied successfully', 'app.components', '@3.4');
          } else {
              this.logger.warn('Failed to retrieve host configuration. Goinf to fault', 'app.components', '@3.5');
              this.faultInitialise();
          }
      } catch (e) {
          this.logger.error('Critical Error during host initialisation.  Going to fault ', e, 'app.components', '@3.6');
          this.faultInitialise();
      }
  }
  


  async reactionInitialise() {
    this.logger.info('Triggered reactionInitialise', 'app.components', '@4');

    return new Promise<void>((resolve, reject) => {
        this.reactionId = this.urlDerviedRection;
        this.logger.trace('Derived reaction ID: ' + this.reactionId, 'app.components', '@4.1');

        this.rs.getReactionData(this.reactionId).subscribe(
            async res => {
                this.logger.trace('Received reaction data', 'app.components', '@4.2');

                this.app_state.setReaction(res.reaction);
                this.logger.trace('Set reaction data in app_state', 'app.components', '@4.3');

                this.recipentName = this.app_state?.current_reaction?.receiver_details?.first_name;
                this.securitySetting = true;
                this.bottomMsg = 'Thank you for waiting ' + this.app_state?.current_reaction?.sender_details?.first_name + '. We are almost there.';
                this.logger.trace('Set recipient name and security settings', 'app.components', '@4.4');

                if (this.app_state?.current_reaction?.security?.enabled === false ||
                    this.app_state?.current_reaction?.security?.enabled === undefined ||
                    this.app_state?.current_reaction?.receiver_details?.phone_number === null ||
                    this.app_state?.current_reaction?.receiver_details?.phone_number === undefined
                ) {
                    this.securityCheck = false;
                } else {
                    this.securityCheck = true;
                }

                this.rs.logAccess(this.reactionId, 'replyApp');
                this.logger.trace('Logged access for reaction ID', 'app.components', '@4.5');

                this.ready1 = '✓';
                resolve();
            },
            error => {
                this.logger.error('Failed to retrieve reaction data: ', error, 'app.components', '@4.6');
                reject(error);
            }
        );
    });
}


  async faultReactionInit() {
    console.log('Fault process initiated');
    const faultReact = await this.FaultResponseData.reactionNotFound();
    console.log('got fault data as: ' + JSON.stringify(faultReact,null,2));
    await this.app_state.setReaction(faultReact);
    this.ready1 = '✗';
    this.faultInitialise();
  }

  async faultInitialise() {
    console.warn('Fault process has been initialised');
    if (!document.location.href.includes('/faultLocal')) {
  //    window.location.href = '/v2/faultLocal';
      return; // Stop further execution as the URL is being redirected
    }
    const faultHost = await this.FaultResponseData.hostNotFound();
    await this.hs.applyConfiguration(faultHost);
    this.ready2 = '✗';
    this.isResolvingAppState = false;
  }



  submitPhone(): void {
    this.logger.info('Triggered submitPhone', 'app.components', '@5');

    let checkNumber: string = '';

    if (this.app_state.appScope === 'getApp') {
        checkNumber = this.normalizePhoneNumber(this.app_state?.current_reaction?.receiver_details?.phone_number);
        this.logger.trace('Normalized phone number for getApp: ' + checkNumber, 'app.components', '@5.1');
    } else if (this.app_state.appScope === 'replyApp') {
        checkNumber = this.normalizePhoneNumber(this.app_state?.current_reaction?.sender_details?.phone_number);
        this.logger.trace('Normalized phone number for replyApp: ' + checkNumber, 'app.components', '@5.2');
    } else {
        this.isResolvingAppState = true;
        this.logger.warn('Unknown appScope, skipping phone check', 'app.components', '@5.3');
        return;
    }

    const inputNumber = this.normalizePhoneNumber(this.phoneNum);
    this.logger.trace('Normalized input phone number: ' + inputNumber, 'app.components', '@5.4');

    if (checkNumber === inputNumber) {
        this.logger.info('Phone number match success', 'app.components', '@5.5');
        this.entryFailed = false;
        this.isResolvingAppState = false;
    } else {
        this.logger.warn('Phone number match failed', 'app.components', '@5.6');
        this.entryFailed = true;
        this.bottomMsg = 'Oops, that was not the right phone number';
    }
}


getLastChildRouteParam(paramName: string): string | null {
  this.logger.info('Triggered getLastChildRouteParam' , 'app.components', '@6');

  this.logger.trace('Checking route parameters for param: ' + paramName, 'app.components', '@6.1');

  let route = this.ar.firstChild;
  this.logger.trace('Initial route: ' + route, 'app.components', '@6.2');

  while (route?.firstChild) {
      route = route.firstChild;
      this.logger.trace('Navigating to next child route: ' + route, 'app.components', '@6.3');
  }

  const output = route?.snapshot.paramMap.get(paramName) || null;
  this.logger.trace('Output parameter value: ' + output, 'app.components', '@6.4');

  return output;
}

/*
  checkPhone() {
    console.log('go time');
  }
    */

  normalizePhoneNumber(phoneNumber: string): string {
    this.logger.info('Triggered normalizePhoneNumber', 'app.components', '@7');

    if (!phoneNumber) {
        this.logger.warn('Phone number is empty or null', 'app.components', '@7.1');
        return '';
    }

    let normalizedNumber = phoneNumber.replace(/[^0-9]/g, '');
    this.logger.trace('Normalized phone number: ' + normalizedNumber, 'app.components', '@7.2');

    return normalizedNumber;
}


}
