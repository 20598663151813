import { Component, ElementRef, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { AppHost, Reaction, StepV3, stateTypes, stateContext, component, postions, AppHostTypes} from '@yoyo/types';
import { AppStateService, ReactionService, TelemetryService, internalControls, getStepData, HostStateService, LoggingService} from '@yoyo/services';
import { BehaviorSubject, Subscription  } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '@yoyo/env';
import {DomSanitizer} from '@angular/platform-browser';

export interface DynamicComponent {}

@Component({
  selector: '_baseTemplate',
  templateUrl: './_baseTemplate.component.html',
})
export class _baseComponent implements OnInit, AfterViewInit {      //base component
  private viewStateSubject = new BehaviorSubject<keyof stateTypes>('base');
  viewStateObsvr$ = this.viewStateSubject.asObservable().pipe(distinctUntilChanged());
  
  //video relatted
  @ViewChild('hidden_preload_video', { static: false }) hiddenPreloadVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('reveal_video', { static: false }) revealVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement: ElementRef<HTMLCanvasElement>;

   //recroding relatted
  @ViewChild('webcam_video') webcamRecorder: ElementRef;
  @ViewChild('recordButton') recordButton: ElementRef;
  @ViewChild('recordCancel') recordCancel: ElementRef;
  @ViewChild('fullSpinner') fullSpinner: ElementRef;
  @ViewChild('postPositioning') postPositioning: ElementRef;
  @ViewChild('fullMediaControlPos') fullMediaControlPos: ElementRef;
  

  stepData: StepV3;
  moduleName: string = '';
  componentName: string = '';
  protected stepNumber: number; 
  viewState: keyof stateTypes = null;
  private subscription: Subscription;
  appScope: AppHostTypes;

  current_reaction: Reaction;
  current_host_config: AppHost;
  heading : string;
  body : string;
  info : string;
  imgSrc: string ='';
  videoSrc: string;
  overlay1 : string;
  overlay2 : string;
  overlay3 : string;
  prmy_btn : string;
  scnd_btn : string;
  thrd_btn : string;
  overalLayShow: boolean = true;
  infoHyperlink: boolean = false;
  
  private config_ordered: any;
  revealContentShown: boolean = false;
  reveal_overlay_content:string = '';

  //write nsg
  replyMSGUser: string = '';

  //legal
  //termsChecked: boolean = false;
  showTerms: boolean = false;
  //showTermsAccept: boolean = false;
  //acceptEnabled: boolean = true;
  returnViewState: keyof stateTypes;
  termsAccepted: boolean = false;

  //video
  videoPoster: string;
  videoDuration: number;
  prog_animation = false;
  overallDuration: number = 0;
  private spinDecrement: number = 100;
  private timeExtend: number = 0;
  private debounceTimeout: any;
  private currentTime = 0;
  private prevTime: number = 0;
  percentProgVal: number = 0;
  spinTitle: string = '';
  private isPaused: boolean = true;
  private videoCompleted: boolean = false;
  private burnDownTime: boolean = false;
  private masterClock: number = 0;

  //recording
  public startStreaming: boolean;
  public startRecording: boolean;
  public webCamPoster: string = '';
  private permission_given: boolean = false;
  //private stream: MediaStream;
  private recAttemptCount: number;
  displayRecordOverlay: boolean = true;
  cameraSrc: any = undefined;
  recPlayback: boolean = false;
  resumeFullScreenPlayback: boolean;
  pauseFullScreenPlayback: boolean;
  recorderStatus: string = 'waiting'
  recordTime: number;
  recordTimeOutput: string;
  maxRecord: number = 120;
  maxPostVideoTime: number = 600;
  snapShotTrigger: boolean = false;
  snapShotImgURL: string;
  recButtonYPos:  string;
  postXpos: string = '';
  postYpos: string = '';
  fullYControlPos: string = '';
  fullXControlPos: string = '';
  spinnerFullX: string =''
  spinnerFullY: string =''
  recordTotalDuration: number = 0;

  constructor(
    protected app_state: AppStateService,
    protected getStepData: getStepData,
    protected ia: internalControls,
    protected renderer: Renderer2,
    protected telemetry: TelemetryService,
    protected rs: ReactionService,
    protected el: ElementRef,
    protected host_state: HostStateService,
    protected domSanitizer: DomSanitizer,
    protected logger: LoggingService,

  ) {
      this.current_host_config = this.host_state.currentHostConfig;
      if (!this.current_host_config){
       // this.host_state.initHostViaParam('testHost');
       // this.current_host_config = this.host_state.currentHostConfig;
       console.warn('this.current_host_config is not set');
      }
      this.current_reaction = this.app_state.current_reaction;
      this.telemetry.startRecordingLoad();
      this.appScope = this.app_state.appScope;
    };

  setStepNumber(componentName: string): void {
    this.componentName = componentName;
    let stepNumber = this.getStepData.getStepNumber(componentName);
    if (stepNumber < 0) {           
      if (environment.editor) {
        stepNumber = -2     //this is a testing condition
      } else {
        stepNumber = -1     //this is fault condition
      }
      console.log('Mock data type: ' + stepNumber + ' will be used');
      this.stepData = this.getStepData.mockStepData(stepNumber);       
      this.moduleName = this.stepData?._module

      this.app_state.setReaction( this.current_reaction);
      this.app_state.current_reaction.id = 'test'
      this.app_state.reactionVideoURL = this.current_reaction.content.videoURL;
    //  console.log('setStepNumber: this.app_state.reactionVideoURL: ' + this.app_state.reactionVideoURL)
    } else {
    //  console.log('this.app_state.appscope: ' + this.app_state.appScope);
    //  console.warn('Cloud configuration being pulled for ' + componentName);
      this.stepNumber = stepNumber;
      this.stepData = this.getStepData.cloudStepDataFromComponentName(componentName);
      this.moduleName = this.stepData?._module;

      if (!this.stepData) {
        if (environment?.editor) {
          this.stepData = this.getStepData.mockStepData(-2);  //this will load step build information

          //console.log('app_content PRE: ' + JSON.stringify(this.current_host_config.app_content, null, 2))
          let newStepKey
          if (this.appScope == 'getApp') {
              newStepKey = this.componentName as keyof typeof this.current_host_config.app_content.getApp.step;  
          } else {
            newStepKey = this.componentName as keyof typeof this.current_host_config.app_content.replyApp.step;  
          }
          this.current_host_config.app_content[this.appScope].step[newStepKey] = {} as StepV3;
     //     console.log('app_content POST: ' + JSON.stringify(this.current_host_config.app_content, null, 2))
        } else {
          console.warn('Mock data for: ' + stepNumber + ' wiil be applied');
          this.stepData = this.getStepData.mockStepData(-1);  //this will load fault information
        }
      }

     // console.log('componentName: ' + this.componentName + ' has data: ' + JSON.stringify(this.stepData, null,2))
    //  this.stepData._module = this.moduleName;
    }
  }

  ngOnInit(): void {
    this.subscription = this.viewStateObsvr$.subscribe(viewState => {
      //console.log('observer sees state change. this.viewState is: ' + this.viewState + ' observer wants: ' + viewState);
      if (this.viewState != viewState){
        this.onViewStateChange(viewState);
      }
    });
    this.setViewState('base');
    this.videoSrc = this.app_state.reactionVideoURL //this.current_reaction.content.videoURL;  //'https://firebasestorage.googleapis.com/v0/b/yoyoreact-dev.appspot.com/o/hosts%2FYoYoV2%2Fvideo%2FYoYoReveal.mp4?alt=media&token=735d3862-547c-4af3-8162-f4ad8045955d'   //this.current_reaction.content.videoURL;  //
    //console.log('NGINT: this.app_state.reactionVideoURL: ' + this.app_state.reactionVideoURL)
    this.prevTime = 0;
    this.config_ordered = this.current_host_config?.overlays?.exp_watch?.sort((a, b) => a.index - b.index) || [];
    if (this.current_host_config?.time_control?.post_record_length){
      console.log('got time value: ')
      this.recordTime =  this.current_host_config.time_control.post_record_length;
      this.recordTimeOutput = this.formatTime(this.recordTime);
    } else {
    //  console.log('Using hardoced time value');
      this.recordTime =  this.maxRecord;
      this.recordTimeOutput = this.formatTime(this.recordTime);
    }
    if (this.current_host_config?.revealVideoDefaults?.postVideoExtendTime) {
      this.timeExtend = this.current_host_config.revealVideoDefaults.postVideoExtendTime
    } else {
      this.timeExtend = 0
    }

  }


  ngAfterViewInit(): void {
    this.telemetry.stopRecordingLoad();
    this.telemetry.startRecordingUser();
  }

  ngOnDestroy(): void {
  //  console.log('go for destory');
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private telemetryRecord(next: number) {
    this.telemetry.stopRecording(this.componentName, this.moduleName, next);
  };

  setViewState(newState: keyof stateTypes) {
    //console.log('state change requested to: ' + newState);
    this.viewStateSubject.next(newState);
  }

  private onViewStateChange( viewState : keyof stateTypes) {
    this.viewState = viewState ;
    this.setStateValues(viewState);
   // console.log('viewState changed to: ',   viewState );
  }

  setStateValues(state: keyof stateTypes) {    
 //   console.log('state being configured for: ' + state);
    const stateContext: stateContext = this.stepData.state?.[state];
    if (stateContext && stateContext.uiElement) {
      this.imgSrc = stateContext.uiElement.img || '';
      this.heading = stateContext.uiElement.heading || '';
      this.body = stateContext.uiElement.body || '';
      this.info = stateContext.uiElement.info || '';
      this.overlay1 = stateContext.uiElement.overlay1 || '';
      this.overlay2 = stateContext.uiElement.overlay2 || '';
      this.overlay3 = stateContext.uiElement.overlay3 || '';
      this.prmy_btn = stateContext.uiElement.prmy_btn || '';
      this.scnd_btn = stateContext.uiElement.scnd_btn || '';
      this.thrd_btn = stateContext.uiElement.thrd_btn || '';
    } else {
      this.imgSrc = '';
      this.heading = '';
      this.body = '';
      this.info = '';
      this.overlay1 = '';
      this.overlay2 = '';
      this.overlay3 = '';
      this.prmy_btn = '';
      this.scnd_btn = '';
      this.thrd_btn = '';
    }
   
  
    //This is required
    this.checkInfoHyperLink();

    if (state === 'base') {
      this.viewPanel(true);
      this.videoSrc = this.app_state.reactionVideoURL
      this.videoCompleted = false;
      this.isPaused = true;
      this.prog_animation = false;
      this.masterClock = 0;
      this.videoDuration = 0;
      this.currentTime = 0;
      this.prevTime = 0;
      this.recAttemptCount = 0;
     // this.termsChecked = false;
      this.recordTotalDuration = 0;
    }
    if (state === 'videoPlaying'){
      this.viewPanel(true);
      //console.log('videoPlaying parameters being setup.  The viewState is: ' + this.viewState)
      try {
        if (!this.revealVideo.nativeElement) {
      //    console.log('no native elemtn yet')
        } else {
     //     console.log('play fault');
          this.revealVideo.nativeElement.play();
        }
      } catch (error) {
        //console.log('the video player faulted with error: ' + error);
  
        //this.internalAction('done', 'fault');
      }
      this.isPaused = false;
      this.prog_animation = true;
    }

    if (state === 'videoPaused'){
      this.viewPanel(true);
      this.prog_animation = false;
      this.isPaused = true;
      try {
        this.revealVideo.nativeElement.pause();
      } catch {
        this.setViewState('base')
      }
    }

    if (state === 'postPlaying'){
      this.viewPanel(true);
      this.prog_animation = true;
      this.isPaused = false;
      if (this.burnDownTime) {
        this.postBurnDown(this.masterClock);
      }
    };

    if (state === 'postPaused'){
      this.viewPanel(true);
      this.prog_animation = false;
      this.isPaused = true;
    }

    if (state === 'preRecord') {
      //console.log('preRecord being set permissionis: ' + this.permission_given);
  //    console.log('startSteaming at preRcord setup start is : ' + this.startStreaming);
  //    console.log('this.recordTime: ' + this.recordTime);
      this.viewPanel(false);
      if (this.permission_given){
        this.recorderStatus  = this.stepData.state.preRecord.uiElement.body;
        this.recordTotalDuration = 0
        if (this.recAttemptCount > 0) {
          this.recordTotalDuration = 0
          this.displayRecordOverlay = false;
           setTimeout(() => {
            this.displayRecordOverlay = true;
            setTimeout(() => {
              this.startWeCambStream();
              this.overallDuration = this.recordTime;
              this.recordTimeOutput = this.formatTime(this.recordTime);
              this.recorderStatus = "Ready to start";
            }, 100)
           }, 100)
        }
        this.startWeCambStream();
        this.recordTotalDuration = 0
        this.overallDuration = this.recordTime;
        this.recordTimeOutput = this.formatTime(this.recordTime);
        this.recorderStatus = "Ready to start";
      } else {
        this.attemptPermissionCheck();
        this.overallDuration = this.recordTime;
      }
    };
    if (state === 'recording') {
    //  console.log('this.overallDuration: ' + this.overallDuration );
      this.viewPanel(false);
      this.masterClock = 0
      this.recAttemptCount++;
      this.isPaused = false;
      this.startRecording = true;
      this.burnDownTime = true;
      this.recorderStatus = "Recording";
      this.postBurnDown(0);
    };

    if (state === 'postRecord') {
    //  console.log('this.masterClock: ' + this.masterClock);
      this.viewPanel(false);
      this.startStreaming = false;
      this.startRecording = false;
      this.isPaused = true;
      this.recPlayback = false;
      this.resumeFullScreenPlayback = false;  //setting to opposite state to ensure change triggers
      this.overallDuration = this.masterClock;
      this.masterClock = 0;
  //    console.log('postRecord set overall duration to: ' + this.overallDuration)
      setTimeout(() => {
   //     console.log('post position is x: ' + this.postXpos + ' Y: '+ this.postYpos)
        if (this.displayRecordOverlay){
          this.postPositioning.nativeElement.style.top = this.postYpos;
        }
      }, 100);
    }

    if (state === 'playFull') {
      this.viewPanel(false);
      this.isPaused = false;
      this.pauseFullScreenPlayback = false;  
      if (!this.recPlayback){
        this.recPlayback = true;
      } else {
        this.resumeFullScreenPlayback = true;
        this.pauseFullScreenPlayback = false;
      }
      setTimeout(() => {
        if (this.displayRecordOverlay){
 //         console.warn('Setting to fullYControlPos: ' + this.fullYControlPos)
          this.fullMediaControlPos.nativeElement.style.top = this.fullYControlPos;
 //         console.log('this.spinnerFullX: ' + this.spinnerFullX);
          this.fullSpinner.nativeElement.style.top = this.spinnerFullY
          this.fullSpinner.nativeElement.style.left = this.spinnerFullX
        }
      }, 100);
    }

    if (state === 'pauseFull') {
      console.log('executing pause full')
      this.viewPanel(false);
      this.isPaused = true;
      this.prog_animation = false;
      this.pauseFullScreenPlayback = true;
      this.resumeFullScreenPlayback = false;
    }

    if (state === 'checkRecTC') {
      this.viewPanel(false);
      this.returnViewState = 'checkRecTC' as keyof stateTypes;
    }

    if (state === 'checkMsgTC') {
      this.viewPanel(false);
      this.returnViewState = 'checkMsgTC' as keyof stateTypes;
    }
    if (state === 'cameraFault') {
      this.viewPanel(true);
      this.displayRecordOverlay = false;
    }

    if (state === 'final') {
      this.viewPanel(false);
        this.videoCompleted = false;
        this.isPaused = true;
        this.prog_animation = false;
        this.masterClock = 0;
        this.videoDuration = 0;
        this.currentTime = 0;
        this.prevTime = 0;
        this.prmyBtn();
        this.accentBtn();
        this.thirdBtn();
    }
  }

    checkInfoHyperLink(){
      this.infoHyperlink = false;
      const componentContext: component = this.stepData.state?.[this.viewState]?.uiAction;
      if (componentContext){
        if(componentContext?.backendAction?.info){
          this.infoHyperlink = true;  
        };
        if(componentContext?.gotoStep?.info){
          this.infoHyperlink = true;  
        };
        if(componentContext?.externalURL?.info){
          this.infoHyperlink = true;  
        };
        if(componentContext?.internalAction?.info){
          this.infoHyperlink = true;  
        };
      } 
    }


  infoClick(event?: Event){
    //boiler plate
  //  console.log('info click ack');
    if(event){
      event.stopPropagation();
    }
    this.showTerms = !this.showTerms;
    const componentContext: component = this.stepData.state?.[this.viewState]?.uiAction;
    if (componentContext?.backendAction?.info) {this.triggerAction(componentContext.backendAction?.info)}
    if (componentContext?.gotoStep?.info) {this.navigate(componentContext.gotoStep?.info)}
    if (componentContext?.externalURL?.info) {this.url(componentContext.externalURL?.info)}
    if (componentContext?.internalAction?.info) {this.internalAction(componentContext.internalAction?.info, 'info')}
    //end boiler plate
  }

  thirdBtn() {
    //boiler plate
      const componentContext: component = this.stepData.state?.[this.viewState]?.uiAction;
      if (componentContext?.backendAction?.thrd_btn) {this.triggerAction(componentContext.backendAction?.thrd_btn)}
      if (componentContext?.gotoStep?.thrd_btn) {this.navigate(componentContext.gotoStep?.thrd_btn)}
      if (componentContext?.externalURL?.thrd_btn) {this.url(componentContext.externalURL?.thrd_btn)}
      if (componentContext?.internalAction?.thrd_btn) {this.internalAction(componentContext.internalAction?.thrd_btn, 'thrd_btn')}
    //end boiler plate
    }

    accentBtn() {
      //boiler plate
      //console.log('scnd click ack');
        const componentContext: component = this.stepData.state?.[this.viewState]?.uiAction;
        if (componentContext?.backendAction?.scnd_btn) {this.triggerAction(componentContext.backendAction.scnd_btn)}
        if (componentContext?.gotoStep?.scnd_btn) {this.navigate(componentContext.gotoStep.scnd_btn)}
        if (componentContext?.externalURL?.scnd_btn) {this.url(componentContext.externalURL.scnd_btn)}
        if (componentContext?.internalAction?.scnd_btn) {this.internalAction(componentContext.internalAction.scnd_btn,'scnd_btn')}
      //end boiler plate
    };

    prmyBtn() {
        //boiler plate
        //console.log('prmy click ack');
          const componentContext: component = this.stepData.state?.[this.viewState]?.uiAction;
          if (componentContext?.backendAction?.prmy_btn) {this.triggerAction(componentContext.backendAction.prmy_btn)}
          if (componentContext?.gotoStep?.prmy_btn) {this.navigate(componentContext.gotoStep.prmy_btn)}
          if (componentContext?.externalURL?.prmy_btn) {this.url(componentContext.externalURL.prmy_btn)}
          if (componentContext?.internalAction?.prmy_btn) {this.internalAction(componentContext.internalAction.prmy_btn,'prmy_btn')}
        //end boiler plate
    };

    cancelRecordBtn(){
      this.internalAction('cancelFull');
    }

    closeLegal(){
      this.internalAction('closeLegal');
    }

    private triggerAction(actionType: string): void {
      if (!environment.editor){
        this.rs.actionTrigger(actionType);
      } else {
        console.warn('External call with: ' + actionType + ' was not made.  Editor is true');
      }
    };
  
    private navigate(destination: string) {
      const stepTo = this.getStepData.getStepNumber(destination);
      if (!environment.editor) {
   //     console.log('Navigiation to destination: ' + destination + ' step number is: ' + stepTo);
        if (stepTo < 0) {
          console.error('Navigation cannot happen because the desitnation is not found.')
        } else {
          this.telemetryRecord(stepTo);
          this.app_state.reactionStepValue$.next(stepTo)
        }  
      } else {
        console.warn('Navigation to step: ' + stepTo + ' is surpressed due to edting');
      }
    };
  
    private url(url: string) {
      window.location.href = url;
    };
  
    private internalAction(actionType: string, requestedBy?: string): void {
     // console.log('Internal action - triggered with: ' + actionType);
      const validKeys: (keyof postions)[] = ['prmy_btn', 'scnd_btn', 'thrd_btn', 'timerEnd'];
      if (actionType == ''){this.setViewState('base')};
      if (actionType == 'start'){
        if (this.viewState === 'postRecord' || this.viewState === 'playFull'){
          this.setViewState('playFull');
        } else {
           this.setViewState('videoPlaying');
        }
      };
      if (actionType == 'resume'){
        if(this.viewState == 'pauseFull' || this.viewState == 'postRecord'){
          this.setViewState('playFull')
        } else {
          if (this.videoCompleted) {
            this.setViewState('postPlaying')
          } else {
            this.setViewState('videoPlaying')
          }
        }
      };
      if (actionType == 'pause'){
        if(this.viewState == 'playFull'){
          this.setViewState('pauseFull')
        } else {
          if (this.videoCompleted) {
            this.setViewState('postPaused')
          } else {
            this.setViewState('videoPaused')
          }
        }
      };
      if (actionType == 'restart'){
        this.setViewState('base');
      };
      if (actionType == 'done'){
        if (this.viewState === 'playFull' || this.viewState === 'pauseFull'){
          this.setViewState('postRecord');
        }
        if (this.viewState === 'videoPlaying' || this.viewState === 'videoPaused' || this.viewState === 'postPlaying' || this.viewState === 'postPaused'){
          const isValidKey = requestedBy && validKeys.includes(requestedBy as keyof postions);
          if (isValidKey) {
            const key = requestedBy as keyof postions;
            const componentContext = this.stepData?.state?.[this.viewState]?.uiAction?.gotoStep?.[key];
            if (componentContext) {
              this.navigate(componentContext);
            } else {
              const anyGoto = this.searchGotoValues(this.viewState)
              if (anyGoto){
                this.navigate(anyGoto);
              } else {
                console.warn('Done was requested via: ' + requestedBy + ' but no goto was defined.  Video will loop')
              }
            }
          } else {
            console.warn('Done was requested via: ' + requestedBy + ' but no goto was defined. Video will loop')
          }
        }
      };
      if (actionType == 'checkRecTC'){
        if (!this.termsAccepted) {
          this.setViewState('checkRecTC');
        } else {
          this.internalAction('videoSend','self')
        }
      };
      if (actionType == 'checkMsgTC'){
        if (!this.termsAccepted) {
          this.setViewState('checkMsgTC');
        } else {
          this.internalAction('msgSend','self')
        }
      };

      if (actionType == 'msgSend'){
        if (!this.stepData?.state?.checkMsgTC){
          if (!environment.editor){
             this.rs.saveMessage(this.replyMSGUser);
          } else {
            console.log('Message send was not attempted due to editor mode');
          }
        } else {
          if(!this.termsAccepted){
            this.setViewState('checkMsgTC');
          } else {
            if (!environment.editor){
              this.rs.saveMessage(this.replyMSGUser);
            } else {
              console.log('Message send was not attempted due to editor mode');
            };
          }
        }
      };
      if (actionType == 'videoSend'){
        if (!this.stepData?.state?.checkRecTC){
          if (!environment.editor){
            this.rs.upload('post'); 
          } else {
            console.log('Video send was not attempted due to editor mode');
          }
        } else {
          if(!this.termsAccepted){
            this.setViewState('checkRecTC');
          } else {
            if (!environment.editor){
              this.rs.upload('post'); 
            } else {
              console.log('Video send was not attempted due to editor mode');
            };
          }
        }
       
      };
      if (actionType == 'setRecorder'){
        if (this.viewState == 'pauseFull'){
          this.setViewState('base');
          setTimeout(() => {
            this.setViewState('preRecord');
          },500)
        } else {
          this.setViewState('preRecord');
        }
        
      };  
      if (actionType == 'record'){
        this.setViewState('recording');
      }; 
      if (actionType == 'stopRecord'){
        this.setViewState('postRecord');
      };   
      if (actionType == 'cancelFull'){
        this.startStreaming = false;
        this.startRecording = false;
        this.isPaused = true;
        this.burnDownTime = false;
        this.setViewState('base');
      }
      if (actionType == 'viewLegal'){
        this.setViewState('viewLegal')
      }
      if (actionType == 'closeLegal'){
        this.setViewState(this.returnViewState)
      }
      if (actionType == 'declineLegal'){
        this.setViewState(this.returnViewState)
        this.termsAccepted = false;
      }
      if (actionType == 'acceptLegal'){
        this.termsAccepted = true;
        if (!environment.editor){
          this.rs.updateLegalAgreement(this.current_reaction.reactionId)
        } else {
          console.log('Legal agreement update not attempted due to editor mode');
        }
        if (this.viewState == 'checkRecTC'){
          this.internalAction('videoSend','self')
        } else if (this.viewState == 'checkMsgTC'){
          this.internalAction('msgSend','self')
        } else {
          console.log('Legal accepted from unkonw source.');
        }
      }
      if (actionType == 'writeMsg'){
        this.setViewState('writeMsg')
      }
    }


    searchGotoValues(state: keyof stateTypes): any {
      const actions: (keyof postions)[] = ['timerEnd', 'prmy_btn', 'scnd_btn', 'thrd_btn'];
  
      const base = this.stepData?.state?.[state]?.uiAction?.gotoStep;
      if (!base) {
        console.log(`No gotoStep found for state "${state}"`);
        return null;
      }
    
      for (const action of actions) {
        const componentContext = base[action];
        if (componentContext) {
          console.log(`Found goto value for state "${state}" and action "${action}":`, componentContext);
          return componentContext;
        }
      }
    
      return null;
    }

//video
//video
//video
//video

  private async startReveal(): Promise<void> {
  //  console.log('start reveal');
    try {
      this.isPaused = false;
      this.revealVideo.nativeElement.play();

      this.renderer.listen(this.revealVideo.nativeElement, 'play', () => {});

      this.renderer.listen(this.revealVideo.nativeElement, 'pause', () => {});

      this.renderer.listen(this.revealVideo.nativeElement, 'ended', () => {
    //    console.log('Video element ended');
        this.videoEnd();
      });
    } catch (error) {
      console.error('Error: start reveal ', error);
    }
  }

  private videoEnd() {
 //   console.log('Video end control function initiated');
    //if ((this as any).stateForm) {this.stateForm.get('currentState').setValue(this.viewState)}
    this.videoCompleted = true;
    this.prog_animation = true;
    this.revealVideo.nativeElement.load();
    this.burnDownTime = true;
    if (this.timeExtend > 0){
      this.postBurnDown(this.currentTime);
    } else {
      this.timerExpired();
    }
  }


  private updateMasterClock(increment: number) {
    this.masterClock = this.masterClock + increment;
   // console.log('The master clock is at: ' + this.masterClock);
    this.spinTitle = Math.round(this.overallDuration - this.masterClock).toString();
    this.recordTimeOutput = this.formatTime(this.overallDuration - this.masterClock);
    
    this.percentProgVal = (1 - this.masterClock / this.overallDuration) * 100;
    let showOverlay = false;
    
    for (let i = 0; i < this.config_ordered.length; i++) {
      const overlay = this.config_ordered[i];
      if (this.masterClock >= overlay.starting_at && this.masterClock <= overlay.ending_at) {
        showOverlay = true;
        this.revealContentShown = true;
      //  console.log('Show overlay element number:', i);
        this.reveal_overlay_content = this.replaceConfigString(overlay.message);
      } else {
        this.revealContentShown = false;
      }
    }
    if (this.masterClock >= this.overallDuration) {
      this.timerExpired();
    }
  }

  private timerExpired():void {
    console.log('I see the video timer experied');
    const componentContext = this.stepData.state[this.viewState].uiAction;
    if (componentContext?.internalAction?.timerEnd){
      this.internalAction(componentContext.internalAction.timerEnd, 'timerEnd');
    } else {
      this.internalAction('done', 'timerEnd');
    }
  }
 
  onVideoMetadataLoaded() {
  //  console.log('meta data loading');
    const videoElement: HTMLVideoElement = this.revealVideo.nativeElement;
    this.videoDuration = videoElement.duration;
    this.overallDuration = this.videoDuration + this.timeExtend
  //  console.log('this.overallDuration '+ this.overallDuration);
    this.prog_animation = true;
    this.isPaused = false;
    this.startReveal();
  }

  public updateCurrentVideoTime(): void {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      if (this.revealVideo) {
        this.currentTime = this.revealVideo.nativeElement.currentTime;
        let increment = this.currentTime - this.prevTime;
        this.updateMasterClock(increment);
        this.prevTime = this.currentTime;
      }
    }, 100);
  }

  async postBurnDown(startTime: number) {
    let counter = ((this.overallDuration - startTime) * 1000) / this.spinDecrement;
   // console.log('burn down counter is: ' + counter);
    do {
    //  console.log('counter: ' + counter);
      await this.delay(this.spinDecrement);
      this.updateMasterClock(this.spinDecrement / 1000);
      counter--;
    } while (counter > 0 && !this.isPaused);
    const internalAction = this.stepData?.state[this.viewState]?.uiAction?.internalAction?.timerEnd;
    if (internalAction){
    //  console.log('Got an action at the end of the timer: ' + internalAction);
      this.internalAction(internalAction,'timerEnd');
    } else {
   //   console.log('No action');
    }
  }

  private replaceConfigString(string_value: string) {
    return string_value
      .replace('{{sender.first_name}}', this.current_reaction?.sender_details?.first_name)
      .replace('{{sender.last_name}}', this.current_reaction?.sender_details?.last_name)
      .replace('{{receiver.first_name}}', this.current_reaction?.receiver_details?.first_name)
      .replace('{{gift_name}}', this.current_reaction?.product?.product_details?.label || '')
      .replace('{{gift_image}}', this.current_reaction?.product?.product_details?.image_src || '')
      .replace('{{gift_description}}', this.current_reaction?.product?.product_details?.product_description || '')
      .replace('{{gift_message}}', this.current_reaction?.gift_message || '')
      .replace('{{gift_landing}}', this.current_reaction?.content?.imageURL || '');
  }

  private async delay(ms: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, ms));
  }


//recroding
//recroding
//recroding
//recroding

  viewPanel(visbile: boolean){
    const linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    if (visbile) {
      const curColor = this.current_host_config?.themev2?.coreStyles?.panel_color || '#fff'
      document.documentElement.style.setProperty('--panel-bg-color', curColor);
    } else {
      document.documentElement.style.setProperty('--panel-bg-color', 'rgba(0,0,0,0');
    }
  }



  onWebeCamPositionReceived(postion: any) {
   // console.log('Position received from child:', postion);
    const setToNumButton =  postion.bottom - 104;
    const setToNumfull =  postion.bottom - 130;
    const cancelX: number = postion.width- 60;
    const spinnerFullxNum = postion.x - 10;
    const cancelY: number = postion.top + 20;
    const cancelXstring = cancelX + 'px';
    const cancelYstring = cancelY + 'px';
    this.spinnerFullY = cancelYstring;
    this.spinnerFullX = spinnerFullxNum + 'px';
    const postYpos = postion.top + postion.height/2 - 44
    const postXpos = postion.left + postion.width/2

    this.recButtonYPos= setToNumButton + 'px';
    this.postXpos = postXpos + 'px'
    this.postYpos = postYpos + 'px'
    this.fullYControlPos = this.recButtonYPos;

   // console.warn('fullYControlPos update: ' + this.fullYControlPos);
    
    if (this.viewState === 'recording' || this.viewState === 'preRecord'){
    //  console.log('Position pre/record state update to: ', this.recButtonYPos);
      if (this.recordButton){
        this.recordButton.nativeElement.style.top = this.recButtonYPos;
      } else {
      //  console.warn('Position not updating waiing timeout.');
        setTimeout(() => {
        //  console.warn('Position timeout elpased setting to:' + this.recButtonYPos);
        try {
          this.recordButton.nativeElement.style.top = this.recButtonYPos;
          //console.warn('Position update - no error');
        } catch(err) {}          
        }, 100)
      }
    }
    if (this.recordCancel ){
   //   console.log('Setting cancel to x: ' + cancelXstring + ' y: ' + cancelYstring);
      this.recordCancel.nativeElement.style.top = cancelYstring;
      this.recordCancel.nativeElement.style.left = cancelXstring;
    } else {
  
        setTimeout(() => {
      //    console.log('Time out style to x: ' + cancelXstring + ' y: ' + cancelYstring);
          try{
            this.recordCancel.nativeElement.style.top = cancelYstring
            this.recordCancel.nativeElement.style.left = cancelXstring
          //  console.log('Pos set going')
          } catch (err) {}
          }, 100)
      }

    if (this.viewState === 'playFull' || this.viewState === 'pauseFull'){
 //     console.warn('This is the new')
      this.fullMediaControlPos.nativeElement.style.top = this.fullYControlPos;
    }
  }

   recordingCompleted(status: boolean){
   // console.log('Recording complete with size: '+ this.rs.reaction_recording_blob.size)
   // this.webCamPoster = this.snapShotImgURL;
    //this.cameraSrc = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.rs.reaction_recording_blob));
    // this.videoSrc = this.cameraSrc;
  //  this.setViewState('videoPlaying');
  }


  recPlaybackDone(status: boolean){
    console.log('Playback finished') ;
    this.setViewState('postRecord');
  }

  async attemptPermissionCheck(): Promise<void> {
    this.recorderStatus = "Checking";
    try {
      const mediaConstraints: MediaStreamConstraints = {
        video: true,
        audio: true,
      };
      await navigator.mediaDevices.getUserMedia(mediaConstraints);
      await this.onPermissionAccept();
    } catch (e) {
      await this.onPermissionDeny();
    }
  }

  async onPermissionAccept(): Promise<void> {
    try {
      this.permission_given = true;
      //console.log('permission granted - streaming state is: '+ this.startStreaming )
      this.setViewState('preRecord');
      this.startStreaming = true;   //this needed to be added here because distinct state change prevnt the re-excution.
    } catch (e) {
        console.log('onPermissionAccept() Error', e);
    }
  }

  async onPermissionDeny(): Promise<void> {
    try {
      this.setViewState('cameraFault'); 
      //this.telemetryRecord(4);

      //this.app_state.reactionStepValue$.next(4);
    } catch (e) {
      console.log('onPermissionAccept() Error', e);
    }
  }

    startWeCambStream() {
      if (this.permission_given) {
        try {
   //     console.log('startStreming is: ' + this.startStreaming + ' heading to true');
          this.startStreaming = true;
         // this.isPaused = true;
          if (!environment.editor) {
            setTimeout(() => {
                  this.snapShotTrigger = true
                }, 1000);
          }
        } catch (error) {
          console.error('Error is record setup: ', error);
        }
      } else {
        console.log('sending back to base because no permission');
        this.setViewState('cameraFault');
      }
    }

    snapShotImgCapture(snapShotImg: string){
      //console.log('snapShotImg rcvd as: ' + snapShotImg);
      this.snapShotImgURL = snapShotImg;
      this.webCamPoster = this.snapShotImgURL;
    }

    webCamPlaybackTime(increment: number){
    //  console.log('webCamPlaybackTime incement: ' +increment );
      this.updateMasterClock(increment);
    }

    formatTime(seconds: number): string {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
    
      return `${minutes}:${this.padToTwo(remainingSeconds)}`;
    }
    
    padToTwo(number: number): string {
      return number <= 9 ? `0${number}` : number.toString();
    }
  }