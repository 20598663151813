import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; 

import {
  SharedComponentsModule,
  SharedPipesModule,
  ReactionStepDirective,
  SvgIconDirective,
  xSVGiconDirective,
  SVGRedoIconDirective,
  SVGPlayIconDirective,
  SVGPauseIconDirective,
  SVGAcceptIconDirective,
  SVGRecrodingIconDirective,
  SVGPreRecordIconDirective
} from '@yoyo/shared';
import { LayoutsModule } from '@yoyo/layouts';

import {
  CreateReactionComponent,
  _baseComponent,
  primaryLand,                          
  secondaryLand,
  thirdLand,
  base1,
  base2,
  base3,
  base4,
  base5,
  base6,
  base7,
  final, 

} from '@yoyo/pages';
import { NgCircleProgressModule } from 'ng-circle-progress';

const reaction_steps = [
  primaryLand,                          
  secondaryLand,
  thirdLand,
  base1,
  base2,
  base3,
  base4,
  base5,
  base6,
  base7,
  final, 
  _baseComponent,
];

const components = [
  CreateReactionComponent,
  ReactionStepDirective,
  SvgIconDirective,
  xSVGiconDirective,
  SVGRedoIconDirective,
  SVGPlayIconDirective,
  SVGPauseIconDirective,
  SVGAcceptIconDirective,
  SVGRecrodingIconDirective,
  SVGPreRecordIconDirective,
  ...reaction_steps,
];

@NgModule({
  declarations: [AppComponent, ...components],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedComponentsModule,
    HttpClientModule,
    LayoutsModule,
    SharedPipesModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
