import { Component } from '@angular/core';
import { AppHost } from '@yoyo/types';
import { HostStateService } from '@yoyo/services';

@Component({
  selector: 'app-header',
  template: `
    <div *ngIf="host_available" 
      class="header w-full flex flex-row items-center p-1 shadow-md justify-center xl:justify-start"
    >
    <div class="w-32">
        <img
          [src]="this.host_state?.logoURL"
          class="w-full h-full xl:ml-4"
        />
      </div>
    </div>
  `,
})
export class HeaderComponent {
  host_config: AppHost;
  host_available: boolean = false;
  constructor(public host_state: HostStateService) {
    this.host_config = this.host_state.currentHostConfig;
    this.host_available = this.host_state.host_available;
  }
}
