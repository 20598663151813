import { Component, OnDestroy, OnInit } from '@angular/core';
import {AppStateService} from '@yoyo/services';

@Component({
  selector: 'app-loader',
  template: `
    <div class="absolute h-full generic-bg w-full z-50 flex flex-col items-center justify-center">

    <p class="text-white text-center text-4xl font-large mt-2.5 pb-4">
        Checking that your gift is ready..GGGG
      </p>  
      <p class="text-white text-center text-2xl font-medium mt-2.5 pb-4">
        Wrapping is all set...GGGGG
      </p>  
      <p class="text-white text-center text-2xl font-medium mt-2.5 pb-4">
        Delivery is ready to go...GGGGGF
      </p>  

      <p class="text-base text-center text-white font-light pt-12 mt-2.5 mx-3">Powered by reply.gift</p>
    </div>
  `,
})


export class LoaderComponent implements OnInit, OnDestroy {
  imageURL: string = 'assets/images/loaders/white.svg';
  msg: string = '';


  constructor(private app_state: AppStateService){}



  messages = [
    'Initiating Smile Generators...',
    'Loading Happiness Boosters...',
    'Activating Feel Good Enhancers...',
    'Assigning Joy Magnifiers...',
    'Charging Grin Suppliers...',
    'Igniting Laughter Engines..',
    'Energizing Positivity Transformers...',
    'Powering up the Joy Circuits...',
    'Sparking the Bliss Amplifiers...',
    'Enabling Radiant Smiles...'
  ];
  loadingMsg: string;
  msgInterval: NodeJS.Timeout;
  usedMessages: string[] = [];

  statusOut: string = this.app_state.curStatus;

  getRandomMessage() {
    if (this.usedMessages.length === this.messages.length) {
      this.usedMessages = [];
    }

    let randomInt = randomIntFromInterval(0, this.messages.length - 1);
    while (this.usedMessages.includes(this.messages[randomInt])) {
      randomInt = randomIntFromInterval(0, this.messages.length - 1);
    }

    this.usedMessages.push(this.messages[randomInt]);
    this.loadingMsg = this.messages[randomInt];
  }

  async ngOnInit() {
    this.msg = this.app_state.curStatus
    this.messages = this.shuffleArray(this.messages);
    this.getRandomMessage();
    this.msgInterval = setInterval(() => {
      this.getRandomMessage();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.msgInterval);
  }

  shuffleArray(array: any[]): any[] {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }
}

function randomIntFromInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

