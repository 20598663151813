import { Directive, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[SVGAcceptIcon]'
})
export class SVGAcceptIconDirective implements OnChanges {
  @Input() enabled: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.enabled) {
      this.updateSVG();
    }
  }

  private updateSVG() {
    const color = this.enabled ? '#00ff00' : '#cccccc'; // Green if true, grey if false
    const svg = `
      <svg
        width="64px"
        height="64px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11" stroke="${color}" stroke-width="2" fill="none"/>
        <path
          d="M9 12.5l2 2l4-4"
          stroke="${color}"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        />
      </svg>
    `;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', svg);
  }
}







