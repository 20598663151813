import { Injectable  } from '@angular/core';
import { valueLabel, stateTypes, actionToState, actionType } from '@yoyo/types';


@Injectable({
  providedIn: 'root',
})
export class internalControls {

  constructor(){}

    getThemeElements(): valueLabel[]{
      const elements: valueLabel[] = [
        {value: '', label: '<not set>'},
        {value: 'common', label: 'Common'},
        {value: 'heading', label: 'Heading'},
        {value: 'body', label: 'Body'}, 
        {value: 'info', label: 'Info'},
        {value: 'overlay', label: 'Overlay'},
        {value: 'prmy_btn', label: 'Primary Button'},
        {value: 'accent_btn', label: 'Secondary Button'},
        {value: 'thrd_btn', label: 'Third Button'},
      ]
      return elements
    };
    
    getBorderStyles(): valueLabel[]{
      const borderStyles: valueLabel[] = [
        {value: 'solid', label: 'Solid'},
        {value: 'none', label: 'No boarder'},
        {value: 'dotted', label: 'Dotted'},
        {value: 'dashed', label: 'Dashed'},
        {value: 'double', label: 'Double'},
      ]
      return borderStyles
    }


    getStateFromAction(actionType: actionType): keyof stateTypes {
      const actions = this.getActions();
      const action = actions.find(a => a.value === actionType);
      if (action) {
        return action.state
      }
      return null
    }

    getActions(): actionToState[] {
      const internalActionList: actionToState[] = [
        {value: '', label: '<not set>', state: 'base'},
        {value: 'start', label: 'Start video', state: 'videoPlaying'},
        {value: 'resume', label: 'Resume paused video', state: 'videoPlaying'},
        {value: 'pause', label: 'Pause playing video', state: 'videoPaused'},
        {value: 'restart', label: 'Restart at initial', state: 'base'},
        {value: 'done', label: 'completed',state: 'final'},
        {value: 'msgSend', label: 'Send Msg', state: 'final'},
        {value: 'videoSend', label: 'Submit Video', state: 'final'},
        {value: 'setRecorder', label: 'Enable recording', state:'preRecord'},
        {value: 'record', label: 'Start recording', state:'recording'},
        {value: 'stopRecord', label: 'Stop recording', state:'postRecord'},
        {value: 'cancelFull', label: 'Set to base state', state:'base'},
        {value: 'timerEnd', label: 'Internal action on timer stop', state:'final'}
      ];
      return internalActionList
    }

/*
    getSteps(): valueLabel[] {
      const steps: valueLabel[] = [
        {value: '', label: '<not set>'},
        {value: '0', label: '0 - v1 landing'},
        {value: '16', label: '16 - Message landing->v2imgLandStep'},
        {value: '18', label: '18 - Post video reveal->v2postMsgStep'},
        {value: '19', label: '19 - Post message reveal->v2postVideoStep'},

      ];
      return steps
    }
*/
    getStates(): valueLabel[] {
      const viewStates:  { value: keyof stateTypes; label: string }[] = [
        {value: 'base', label: 'base - must set'},
        {value: 'videoPlaying', label: 'video playing'},
        {value: 'videoPaused', label: 'video puased'},
        {value: 'preRecord', label: 'pre Record'},
        {value: 'recording', label: 'Record video'},
        {value: 'postRecord', label: 'Post recording'},
        {value: 'checkRecTC', label: 'T&C check at rec'},
        {value: 'checkMsgTC', label: 'T&C check at msg'},
        {value: 'writeMsg', label: 'Write reply'},
        {value: 'cameraFault', label: 'Camera false'},
        {value: 'playFull', label: 'Play in full screen'},
        {value: 'pauseFull', label: 'Pause in full'},
        {value: 'loading', label: 'pre video ready - low priority'},
        {value: 'final', label: 'Final - action control'},
      ];
      return viewStates
    }
   
}