const baseUrl = 'https://australia-southeast1-yoyoreact-dev.cloudfunctions.net/';

export const environment = {
  production: false,
  version: '2.0.0',
  fontDefault: 'Roboto',
  editor: false,
  logging: {
    level: 'TRACE'
  },
  styling: {
    heading: {
      font_weight: '400',
      text_color: '#616161'
    },
    body: {
      font_weight: '400',
      text_color: '#616161'
    },
    info: {
      font_weight: '400',
      text_color: '#616161'
    },
    overlay: {
      font_weight: '400',
      text_color: '#616161'
    },
    prmy_btn: {
      font_weight: '400',
      text_color: '#5F4293',
      fill: "#FCCC2B",
      border_color: "#000"
    },
    accent_btn: {
      font_weight: '400',
      text_color: '#616161',
      fill: "",
      border_color: "#000"
    },
    thrd_btn: {
      font_weight: '400',
      text_color: '#616161',
      fill: "rgba(0,0,0,0)",
      border_color: "#000"
    },
  },
  firebase: {
    apiKey: 'AIzaSyBpjelLELFHhPdMPgcbsNXMNpGIW17b6bY',
    authDomain: 'yoyoreact-dev.firebaseapp.com',
    projectId: 'yoyoreact-dev',
    storageBucket: 'yoyoreact-dev.appspot.com',
    messagingSenderId: '895012247591',
    appId: '1:895012247591:web:ce3444264b0c53a2dc5602',
  },
  account: {
    email: 'service_account_frontend@reply.gift',
    password: "UBC6O41JsdULuR706L0lWz",
  },
  api: {
    reaction: {
      create: baseUrl + 'default-reaction/create',
      update: baseUrl + 'default-reaction/update',
      send: baseUrl + 'default-reaction/send',
      agree: baseUrl + 'default-reaction/agree',
      convert: baseUrl + 'default-reaction/convert',
      telemetry: baseUrl + 'default-telemetry/upsertv2',
      actionTrigger: baseUrl + 'default-clientAction/clientAction',
      get: baseUrl + 'default-reaction/get',
      logAccess: baseUrl + 'default-reaction/logAccess'
    },
    host: {
      retrieveHost: baseUrl + 'default-host/retrieveHosts',
    },
    queue: {
      addTo_q_video: baseUrl + 'default-queues/addTo_q_video_user',
      addTo_q_email: baseUrl + 'default-queues/addTo_q_email',
    }
  },
};
