import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[SVGPlayIcon]'
})
export class SVGPlayIconDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {
    const strokeColor: string = '#000000'; // Default stroke color is black
    const svg = `
<svg
    width="64px"
    height="64px"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
>
    <!-- Outer circle (black edge) -->
    <circle cx="40" cy="40" r="38" fill="none" stroke="#000000" stroke-width="1"/>
    <!-- Middle circle (grey band) -->
    <circle cx="40" cy="40" r="34" fill="none" stroke="#ffffff" stroke-width="6"/>
    <!-- Inner circle (black inner edge) -->
    <circle cx="40" cy="40" r="30" fill="none" stroke="#000000" stroke-width="1"/>
    <!-- Play icon triangle with black border and grey fill -->
    <polygon points="30,20 60,40 30,60" fill="#ffffff" stroke="#000000" stroke-width="1"/>
</svg>
    `;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', svg);
  }
}














