import { Injectable } from '@angular/core';
import { AppStateService, HostStateService } from '@yoyo/services';
import { AppHost, Reaction} from '@yoyo/types';


@Injectable({
  providedIn: 'root',
})
export class setVideoURLService {

  current_reaction: Reaction;
  current_host_config: AppHost;


  constructor(private app_state: AppStateService, private host_state: HostStateService) {
    this.current_host_config = this.host_state.currentHostConfig;
    this.current_reaction = this.app_state.current_reaction;
  }


  sourceVideoURLGetSet(): string{
    if (this.current_reaction?.content?.videoURL){
      this.app_state.reactionVideoURL = this.current_reaction.content.videoURL;
      return this.current_reaction.content.videoURL
    }
    if (this.current_host_config?.revealVideoDefaults?.defaultRevealURL){
      this.app_state.reactionVideoURL = this.current_host_config.revealVideoDefaults.defaultRevealURL
      return this.current_host_config.revealVideoDefaults.defaultRevealURL
    }
    return 'false'
  }
}