import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class FaultResponseData {
  constructor() {}

  async hostNotFound(): Promise<{ name: string; app_content: any; themev2: any }> {
    const output = {
      recordDefaults: {
          maxRecordTime: 180
      },
      name: "faultNoCloudHost",
      id: "faultNoCloudHost",
  themev2: {
    elementStyles: {
      defaults: {}
    },
    coreStyles: {
      header_bg_color: "#FFF",
      background_color: "",
      background_url: "https://firebasestorage.googleapis.com/v0/b/yoyoreact-dev.appspot.com/o/hosts%2FYoYoV2%2Fapp_images%2Fbackground%2Fyoyo_background_pattern.svg?alt=media&token=3ab5a185-ca8e-4c34-a4e8-88a57c88707a",
      logo: "https://firebasestorage.googleapis.com/v0/b/yoyoreact-dev.appspot.com/o/hosts%2FYoYoV2%2Fapp_images%2Flogo%2Flogo.svg?alt=media&token=91246c82-e1bb-4989-a73d-fcf25dd632f4_"
    }
  },
  revealVideoDefaults: {
    defaultRevealURL: ""
  },
      
      app_content: {
        getApp: {
          step: {
            primaryLand: {
              _module: 'Local fault response to no host',
              state: {
                base: {
                  uiElement: {
                    heading: 'Sorry {{receiver.first_name}}',
                    body: "It is broken and we can't progress any further",
                    info: 'view.gift',
                    prmy_btn: '',
                  },
                  uiAction: {
                    gotoStep: {
                      prmy_btn: 'final',
                    },
                    internalAction: {
                      prmy_btn: '',
                    },
                    backendAction: {
                      prmy_btn: '',
                    },
                    externalURL: {
                      prmy_btn: '',
                    },
                  },
                },
              },
            },
            secondaryLand: {
              _module: 'Local fault response to no host',
              state: {
                base: {
                  uiElement: {
                    heading: 'Sorry {{receiver.first_name}}',
                    body: "It is broken and we can't progress any further",
                    info: 'view.gift',
                    prmy_btn: '',
                  },
                  uiAction: {
                    gotoStep: {
                      prmy_btn: 'final',
                    },
                    internalAction: {
                      prmy_btn: '',
                    },
                    backendAction: {
                      prmy_btn: '',
                    },
                    externalURL: {
                      prmy_btn: '',
                    },
                  },
                },
              },
            },
            thirdLand: {
              _module: 'Local fault response to no host',
              state: {
                base: {
                  uiElement: {
                    img: '/assets/images/denied.png',
                    heading: 'Sorry {{receiver.first_name}}',
                    body: "It is broken and we can't progress any further",
                    info: 'view.gift',
                    prmy_btn: '',
                  },
                  uiAction: {
                    gotoStep: {
                      prmy_btn: 'final',
                    },
                    internalAction: {
                      prmy_btn: '',
                    },
                    backendAction: {
                      prmy_btn: '',
                    },
                    externalURL: {
                      prmy_btn: '',
                    },
                  },
                },
              },
            },
            final: {
              _module: 'final influence next action',
              state: {
                base: {},
              },
            },
          },
        },
        replyApp: {
          step: 
          {
            primaryLand: {
              _module: 'primary entry if video found',
              state: {
                base: {
                  uiElement: {
                    img: '/assets/images/denied.png',
                    heading: 'Sorry {{sender.first_name}}',
                    body: "It is broken and we can't progress any further",
                    info: 'reply.gift',
                    prmy_btn: '',
                  },
                  uiAction: {
                    gotoStep: {
                      prmy_btn: 'final',
                    },
                    internalAction: {
                      prmy_btn: '',
                    },
                    backendAction: {
                      prmy_btn: '',
                    },
                    externalURL: {
                      prmy_btn: '',
                    },
                  },
                },
              },
              },
              secondaryLand: {
              _module: 'primary entry if video found',
              state: {
                base: {
                    uiElement: {
                      img: '/assets/images/denied.png',
                      heading: 'Sorry {{sender.first_name}}',
                      body: "It is broken and we can't progress any further",
                      info: 'reply.gift',
                      prmy_btn: '',
                    },
                    uiAction: {
                      gotoStep: {
                        prmy_btn: 'final',
                      },
                      internalAction: {
                        prmy_btn: '',
                      },
                      backendAction: {
                        prmy_btn: '',
                      },
                      externalURL: {
                        prmy_btn: '',
                      },
                    },
                  },
                },
              },
              thirdLand: {
                _module: 'primary entry if video found',
                state: {
                  base: {
                    uiElement: {
                      img: '/assets/images/denied.gif',
                      heading: 'Sorry {{sender.first_name}}',
                      body: "Regrettably we cannot progress from here.",
                      info: 'reply.gift',
                      overlay1: 'This is not where we would like to be!',
                      prmy_btn: '',
                    },
                    uiAction: {
                      gotoStep: {
                        prmy_btn: 'final',
                      },
                      internalAction: {
                        prmy_btn: '',
                      },
                      backendAction: {
                        prmy_btn: '',
                      },
                      externalURL: {
                        prmy_btn: '',
                      },
                    },
                  },
                },
              },
              final: {
                _module: 'final influence next action',
                state: {
                  base: {},
                },
              },
            },
          },
        },
      }
    return output;
  }


  async reactionNotFound(): Promise<any> {
    const output = {
      reactionId: "faultLocal",
      receiver_details: {
        first_name: "Gift reciever",
        last_name: "",
        phone_number: "",
        email_address: ""
      },
      sender_details: {
        first_name: "Gift giver",
        last_name: "",
        phone_number: "",
        email_address: ""
      },
      metaData: {
        creation: {
          source: "manual creation"
        }
      },
      security: {
        enabled: false
      },
      product: {
        product_details: {
          label: "fault",
          receiver_details: "",
          image_src: ""
        }
      },
      gift_message: "Configuration fault does not support progression",
      content: {
        videoURL: "",
        imageURL: ""
      }
    };
    return output;
  }
}
