import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
<button
      [ngClass]="{
        'prmy_btn': btn_type === 'primary',
        'accent_btn': btn_type === 'accent',
        'thrd_btn': btn_type === 'third',
        'selected': selected
      }"
      class="py-2 px-4 rounded-md min-w-min border-2"
      [disabled]="disabled"
      (click)="onButtonClick.emit()"
    >
      {{ btn_txt }}
    </button>
  `,
})
export class ButtonComponent {
  @Input() btn_txt = '';
  @Input() btn_type: 'primary' | 'accent' | 'third' = 'primary';
  @Input() disabled = false; 
  @Input() selected = false; 

  @Output() onButtonClick = new EventEmitter();

  handleClick() {
    if (!this.disabled) {
      this.onButtonClick.emit();
    }
  }

  constructor() {}
}
