import { Injectable, inject } from '@angular/core';
import { AppStateService, HostStateService, VideoPreloadService, getStepData, LoggingService } from '@yoyo/services';
import { ComponentTypes, AppHostTypes, Reaction,AppHost  } from '@yoyo/types';

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  private logger = inject(LoggingService);

  current_reaction: Reaction;
  current_host_config: AppHost;


  constructor(private app_state: AppStateService, 
              private host_state: HostStateService,
              private videoService: VideoPreloadService,
              private getStepData: getStepData
          ) {
    this.current_host_config = this.host_state.currentHostConfig;
    this.current_reaction = this.app_state.current_reaction;
    this.logger.trace('current_reaction ' + JSON.stringify(this.current_reaction,null,2), 'landingService', '@0');
  }

  setAppLanding(appScope: AppHostTypes): ComponentTypes {
    this.logger.info('Triggered setAppLanding', 'landingService', '@1');
    this.logger.trace('Setting app landing page for app type: ' + appScope, 'landingService', '@1.1');

    if (appScope === 'getApp') {
        const setVideo = this.sourceVideoURLGetSet(appScope);
        this.logger.trace('Video source set result: ' + setVideo, 'landingService', '@1.2');

        if (setVideo != 'false') {
            this.videoService.preloadVideo(this.app_state.reactionVideoURL)
                .catch(err => {
                    this.logger.warn('Preload video failed for getApp', 'landingService', '@1.3');

                    if (setVideo === 'user') {
                        this.logger.warn('User video is not valid. Attempting host video', 'landingService', '@1.4');
                        
                        if (this.current_host_config?.revealVideoDefaults?.defaultRevealURL) {
                            this.app_state.reactionVideoURL = this.current_host_config.revealVideoDefaults.defaultRevealURL;
                            const stepTo = this.getStepData.getStepNumber('primaryLand');
                            this.app_state.reactionStepValue$.next(stepTo);

                            this.videoService.preloadVideo(this.app_state.reactionVideoURL)
                                .catch(err => {
                                    this.logger.warn('Host video preload failed. Moving to secondary landing', 'landingService', '@1.5');
                                    const stepTo = this.getStepData.getStepNumber('secondaryLand');
                                    this.app_state.reactionStepValue$.next(stepTo);
                                });
                        } else {
                            this.logger.warn('No default host video found. Moving to secondary landing', 'landingService', '@1.6');
                            const stepTo = this.getStepData.getStepNumber('secondaryLand');
                            this.app_state.reactionStepValue$.next(stepTo);
                        }
                    } else {
                        this.logger.warn('Preload failed, moving to secondary landing', 'landingService', '@1.7');
                        const stepTo = this.getStepData.getStepNumber('secondaryLand');
                        this.app_state.reactionStepValue$.next(stepTo);
                    }
                });
            return 'primaryLand';
        } else {
            this.logger.trace('No valid video for getApp. Returning secondaryLand', 'landingService', '@1.8');
            return 'secondaryLand';
        }
    } else {  // replyApp
        const setVideo = this.sourceVideoURLGetSet(appScope);
        this.logger.trace('Video source set result for replyApp: ' + setVideo, 'landingService', '@1.9');

        if (setVideo != 'false') {
            this.videoService.preloadVideo(this.app_state.reactionVideoURL)
                .catch(err => {
                    this.logger.warn('Preload video failed for replyApp. Moving to secondary landing', 'landingService', '@1.10');
                    const stepTo = this.getStepData.getStepNumber('secondaryLand');
                    this.app_state.reactionStepValue$.next(stepTo);
                });
            return 'primaryLand';
        } else {
            if (this.current_reaction?.response?.msg) {
                this.logger.trace('Message found in response. Returning secondaryLand', 'landingService', '@1.11');
                return 'secondaryLand';
            } else {
                this.logger.trace('No message in response. Returning thirdLand', 'landingService', '@1.12');
                return 'thirdLand';
            }
        }
    }
}



sourceVideoURLGetSet(appScope: AppHostTypes): string {
  this.logger.info('Triggered sourceVideoURLGetSet', 'landingService', '@3');
  this.logger.trace('Determining video URL for app type: ' + appScope, 'landingService', '@3.1');

  if (appScope === 'getApp') {
      if (this.current_reaction?.content?.videoURL) {
          this.logger.warn('Reaction Video URL: ' + this.current_reaction.content.videoURL, 'landingService', '@3.2');
          this.app_state.reactionVideoURL = this.current_reaction.content.videoURL;
          return 'user';
      } else if (this.current_host_config?.revealVideoDefaults?.defaultRevealURL) {
          this.app_state.reactionVideoURL = this.current_host_config.revealVideoDefaults.defaultRevealURL;
          this.logger.warn('Using global hosted video URL', 'landingService', '@3.3');
          return 'host';
      } else {
          this.logger.trace('No video found for getApp', 'landingService', '@3.4');
          return 'false';
      }
  } else {  // source is replyApp
      if (this.current_reaction?.response?.video) {
          this.app_state.reactionVideoURL = this.current_reaction.response.video;
          this.logger.trace('Reaction response video URL set: ' + this.app_state.reactionVideoURL, 'landingService', '@3.5');
          return 'user';
      } else {
          this.logger.trace('No video found for replyApp', 'landingService', '@3.6');
          return 'false';
      }
  }
}

}
