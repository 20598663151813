import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[SVGRecordingIcon]'
})
export class SVGRecrodingIconDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const svg = `
    <svg
    class="w-16 h-16"
    viewBox="0 0 104 104"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- Outer red ring with transparent inner area -->
    <circle cx="52" cy="52" r="50" fill="transparent" stroke="#ff0000" stroke-width="4" />
    
    <!-- Center red button with 70% transparency -->
    <circle cx="52" cy="52" r="30" fill="rgba(255, 0, 0, 0.7)" />
  </svg>
    `;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', svg);
  }
}
