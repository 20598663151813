import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStateService, AuthService, LoggingService } from '@yoyo/services';
import {environment} from '@yoyo/env';
import { Telemetry, TelemetryResponse } from '@yoyo/types';
import { Observable } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TelemetryService  {
  telemetryData: Telemetry = {outcomes:{}, timeStamps:{}, device:{}, steps:[] };
  private startTimeUser: Date;
  private startTimeLoad: Date;
  private loadTime: number;
  
  constructor(private http: HttpClient,
              private app_state: AppStateService,
              private authService: AuthService,
              private logger: LoggingService
            ) 
              {}
  
  setInitialTime(){
    this.telemetryData.timeStamps.initialised = new Date();
  }

  setUploadTime(){
    this.telemetryData.timeStamps.uploadTime = new Date();
  }

  setStatus(status: string){
    this.telemetryData.timeStamps.uploadTime = new Date();
    this.telemetryData.outcomes.status = status;
  }

  startRecordingLoad() {
  //  console.log('telem Load start')
    this.startTimeLoad = new Date();
    this.loadTime = 0;
  }

  startRecordingUser() {
    this.startTimeUser = new Date();
  }

  stopRecordingLoad() {
//    console.log('telem Load stop')
    const endTime = new Date();
    this.loadTime = (endTime.getTime() - this.startTimeLoad.getTime()) / 1000;
  }

  setVideo(msg: string){
    this.telemetryData.outcomes.video = msg;
  }

  stopRecording(componentName: string, _module?:string, navigateTo?: number) {
 //   console.log('stopRecording - step: ' + stepNumber)
    const endTime = new Date();
    const timeSpent = (endTime.getTime() - this.startTimeUser.getTime()) / 1000; // Convert to seconds
    this.telemetryData.steps.push({_module, componentName: componentName, 'loadTime': this.loadTime, timeSpent, navigateTo});
   // console.log('this.telmetryData.steps ' + JSON.stringify(this.telemetryData.steps, null,2));
    this.postTelemetry()
  }

  async initialise() {
    try {
      await this.getDeviceInfo();
      
      this.setStatus("initialised");
    } catch (error) {
      console.error('Error during initialization:', JSON.stringify(error, null, 2));
      // Handle the error as needed
    }
  }

 
  postTelemetry(): void {
 //   console.log('telem post to: ' + environment.api.reaction.telemetry);
//    console.log('payload is: ' +JSON.stringify(this.telemetryData,null,2));
   // console.log('this.app_state.current_reaction.id: ' + this.app_state.current_reaction.id);
   if (false){
    try {
        const authToken =  'Bearer ' + this.authService.getAuthServiceToken();
         this.http.post<TelemetryResponse>(
                environment.api.reaction.telemetry,
                {
                    'reactionId': this.app_state.current_reaction.id,
                    'context'   :'getApp',
                    'payload'   : this.telemetryData,
                   // 'index'     : inexOutput
                },
                {
                    headers: {
                        ['Content-Type']: 'application/json',
                        'Authorization' : authToken,
                    },
                    responseType: 'json',
                }
            ).pipe(
                tap(response => {
                  this.app_state.telemetryIndex = response.indexVal;
                //  console.log('Response:' + JSON.stringify(response, null, 2));
                // console.log('this.app_state.telemetryIndex:' + this.app_state.telemetryIndex);
                  
                }),
                catchError((error) =>{
                    console.log('Telemetry post returned and error ' + error);
                    throw error;
                })
            ).subscribe();  // Simple subscription without handling response
    } catch (e) {
        throw e;
    }
  } else {
    this.logger.warn('telemetry turned off');
  }
  }



  async getDeviceInfo() {
    this.telemetryData.device.userAgent = navigator.userAgent;
    this.telemetryData.device.os = this.getOperatingSystem();
    this.telemetryData.device.viewportSize = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    this.telemetryData.device.language = navigator.language;
    this.telemetryData.device.screenResolution = {
      width: screen.width,
      height: screen.height
    };
    this.telemetryData.device.colorDepth = screen.colorDepth;
    this.telemetryData.device.timezone = new Date().getTimezoneOffset();
    this.telemetryData.device.isTouchscreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    this.telemetryData.device.cookiesEnabled = navigator.cookieEnabled;
    this.telemetryData.device.localStorageEnabled = typeof(Storage) !== "undefined";
    this.telemetryData.device.hardwareConcurrency = navigator.hardwareConcurrency;
    this.telemetryData.device.ip = await this.getUserIP();
   // this.telemetryData.device.batteryLevel = await this.getBatteryLevel();
//   console.log('Device Info:', this.telemetryData.device);
  }
  
  
  private getOperatingSystem() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Win') !== -1) return 'Windows';
    if (userAgent.indexOf('Mac') !== -1) return 'Macintosh';
    if (userAgent.indexOf('Linux') !== -1) return 'Linux';
    if (userAgent.indexOf('Android') !== -1) return 'Android';
    if (userAgent.indexOf('iOS') !== -1) return 'iOS';
    return 'Unknown OS';
  }

  private getUserIP(): Promise<string> {
    return new Promise((resolve) => {
        // For simplicity, we'll assume there's a method to get the user's IP
        // Replace this with your actual implementation
        this.http.get("https://api.ipify.org/?format=json").subscribe((response: any) => {
            let IP = response.hasOwnProperty('ip') ? response['ip'] : '0.0.0.0';
   //         console.log('IP is: ' + IP);
            resolve(IP);
        }, (error) => {
            console.error('Error fetching IP:', error);
            resolve('0.0.0.0');
        });
    });
}


}
