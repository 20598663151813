import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[SVGRedoIcon]'
})
export class SVGRedoIconDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const svg = `
      <svg
          width="64px"
          height="64px"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="32" r="29" stroke="#ff0000" stroke-width="6" fill="none"/>
          <path d="M52.622,34.746l-6.749,6.75c-0.562,0.562-1.326,0.879-2.122,0.879s-1.56-0.316-2.121-0.879l-6.75-6.75
            c-1.171-1.171-1.171-3.071,0-4.242c1.171-1.172,3.071-1.172,4.242,0l1.832,1.832C40.486,26.697,35.758,22.25,30,22.25
            c-6.064,0-11,4.935-11,11c0,6.064,4.936,11,11,11c1.657,0,3,1.343,3,3s-1.343,3-3,3c-9.373,0-17-7.626-17-17s7.627-17,17-17
            c8.936,0,16.266,6.933,16.936,15.698l1.442-1.444c1.172-1.172,3.072-1.172,4.242,0C53.792,31.674,53.792,33.574,52.622,34.746z" 
            fill="#ff0000"/>
        </svg>
    `;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', svg);
  }
}
