export * from './button.component';
export * from './header.component';
export * from './loader.component';
export * from './player-controls.component';
export * from './player-no-controls.component';
export * from './player-review.component';
export * from './webcamRecorder.component';
export * from './progressBar.component';
export * from './legal/legal.component';
export * from './components.module';
