import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AppHost, Reaction} from '@yoyo/types';
import * as RecordRTC from 'recordrtc';
import {
  HostStateService,
  AppStateService,
  ReactionService,
} from '@yoyo/services';
import {PlayerNoControlsComponent, manualDelay} from '@yoyo/shared';

@Component({
  selector: 'progress-bar',
  template:`
`,
})
export class ProgressBarComponent {

  constructor(
  ) {

  }
  @Input()  display: boolean;
  @Input()  currentTime: number;
  @Input()  duration: number;

  percentageComplete = () =>   Math.round(((this.currentTime / this.duration) * 100));




  }
