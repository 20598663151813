import { Component, ElementRef, OnInit ,Renderer2 } from '@angular/core';
import { _baseComponent} from '../../0_baseCode/base/_baseComponent.component';
import { AppStateService, ReactionService, TelemetryService, internalControls, getStepData, HostStateService, LoggingService } from '@yoyo/services';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  templateUrl: '../../0_baseCode/base/_baseTemplate.component.html'
})
export class base4 extends _baseComponent implements OnInit {
  constructor(
    app_state: AppStateService,
    getStepData: getStepData,
    ia: internalControls,
    renderer: Renderer2,
    telemetry: TelemetryService,
    rs: ReactionService,
    el: ElementRef,
    host_state: HostStateService,
    domSanitizer: DomSanitizer,
    logger: LoggingService,
)  {
  super(app_state, 
        getStepData, 
        ia, 
        renderer, 
        telemetry,
        rs,
        el,
        host_state,
        domSanitizer,
        logger
      )
      this.setStepNumber('base4');
}

  ngOnInit() {
    super.ngOnInit();
  }
}