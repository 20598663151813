import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[xSvgIcon]'
})
export class xSVGiconDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const svg = `
      <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="3" y1="3" x2="21" y2="21" stroke="rgba(255, 255, 255, 0.7)" stroke-width="3" stroke-linecap="round" />
        <line x1="3" y1="21" x2="21" y2="3" stroke="rgba(255, 255, 255, 0.7)" stroke-width="3" stroke-linecap="round" />
      </svg>
    `;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', svg);
  }
}
