//This service crafts the message body and stores it.

import {Injectable, inject} from '@angular/core';
import {AppStateService,CloudStorageService, HostStateService, LoggingService} from '@yoyo/services';
import {emailAttributes, emailContext, Reaction, IFTT} from '@yoyo/types';


@Injectable({
  providedIn: 'root',
})
export class MessageCreationService {
  private logger = inject(LoggingService);

  email_to_sender: any = {};

  constructor(
    private app_state: AppStateService,
    private host_state: HostStateService,
  ){}


  async createEmailMsg() {
    this.logger.info('createEmailMsg triggered','responseMessage.service','@1');
    const contextKey: keyof emailContext = 'replyNotify'  // not correct yet
    const reactionData = this.app_state.current_reaction;
    const emailContextData: emailAttributes | undefined = this.host_state.currentHostConfig?.comms_content?.email?.[contextKey] as emailAttributes | undefined;
    if (!emailContextData){
      this.logger.warn('createEmailMsg does not have replyNotify configuration','responseMessage.service','@1.1');
      return false;
    }
  
    const host = reactionData.host_id || '';
    const link = this.replaceEmailURLString(emailContextData?.btn_url  || '', this.app_state.current_reaction.reactionId, host);

    const variables = {
      logoUrl: this.host_state.currentHostConfig?.themev2?.coreStyles?.logo || '' ,
      title_text: this.replaceVariable(emailContextData?.heading || 'You have a reply', reactionData),
      btn_txt: emailContextData?.btn_txt || 'View Reply',
      btn_link: link,
      btn_text_color: this.host_state.currentHostConfig?.themev2?.elementStyles?.prmy_btn?.text_color || '#222222',
      btn_bg_color: this.host_state.currentHostConfig?.themev2?.elementStyles?.prmy_btn?.fill || '#007BFF',
      btn_border_color: this.host_state.currentHostConfig?.themev2?.elementStyles?.prmy_btn?.border_color || 'transparent',
      link_var: link,
      body_text: this.replaceVariable(emailContextData?.body_html || 'You have a reply to your gift!', reactionData),
      supplement: this.replaceVariable(emailContextData?.supplement || '', reactionData),
      Footer: emailContextData?.footer || 'Reply.Gift is gift delivery service. You are getting this email because you bothgt a gift supplied information to us.'
    }

    let templateId: number = 0;
    try {
      templateId = parseInt(emailContextData?.externalId || '0', 10);
      if (isNaN(templateId)) {
        throw new Error('Invalid TemplateID');
      }
    } catch (error: any) {
      templateId = 6308642;
    }

    let email = reactionData.sender_details?.email_address || '';;
    
    if (!email){
      this.logger.warn('createEmailMsg does not know who to send to','responseMessage.service','@1.2');
      return false;
    }


  const output = {
      From: {
          Email: emailContextData?.fromAddress|| 'no-reply@reply.gift',
          Name: emailContextData?.fromAddress || 'reply.gift',
          },
      To: [
              {
              Email: email
              },
          ],
      Subject: this.replaceVariable(emailContextData?.subject || 'Message from Reply.Gift', reactionData),
      TemplateID: templateId,
      TemplateLanguage: true,
      Variables: variables
  }
  
    this.email_to_sender = output;
    this.logger.debug('createEmailMsg created: ' + JSON.stringify(this.email_to_sender,null,2),'responseMessage.service','@1.1');
    return true;
  }


  createEvaluatePayload(checkConversion: boolean): IFTT{
    const reactionData = this.app_state.current_reaction;
    let IFTT: IFTT
    if (!checkConversion){      //
      IFTT = {
        "host": reactionData.host_id,
        "reactionId": reactionData.reactionId || reactionData.id,
        "condition" : {
            "input" : {
              "value" : true
            },
           "expression" : 'equals',
           "reference" : {
           "value" : true
              }
        },
        "true_action" : "sendEmail",
        "false_action" : "retry",
        "retry" :{
          "maxRetry": 3,          
          "currentCount" : 0
        },
        "payload": {
          "messageSeries": "recieverNotification",
          "message": this.email_to_sender
      }
    }
    
  } else {
    IFTT = {
      "host": reactionData.host_id,
      "reactionId": reactionData.reactionId || reactionData.id,
      "condition" : {
         "input" : {
          "collection" : 'y!_' + reactionData.host_id,
          "document" :reactionData.reactionId || reactionData.id,
          "Field" : 'response.converted'
         },
         "expression" : 'equals',
         "reference" : {
         "value" : true
            }
      },
      "true_action" : "sendEmail",
      "false_action" : "retry",
      "retry" :{
        "maxRetry": 3,          
        "currentCount" : 0
      },
      "payload": {
        "messageSeries": "recieverNotification",
        "message": this.email_to_sender
    }
  }
  }
  return IFTT;
}


  replaceEmailURLString(input: string, reaction_id: string, host: string) {
    const reactUpdate = input.replace(/{{reaction_id}}/gi, reaction_id);
    const output = reactUpdate.replace(/{{host_name}}/gi, host);
    return output
  }

  replaceVariable(inputString: string, reactionData: Reaction): string {
    
    const formatDate = (dateInput: any): string => {
      let date: Date;

      if (dateInput && dateInput._seconds !== undefined && dateInput._nanoseconds !== undefined) {
        // Firestore timestamp
        date = new Date(dateInput._seconds * 1000 + dateInput._nanoseconds / 1000000);
      } else {
        date = new Date(dateInput);
      }

      if (isNaN(date.getTime())) {
        // dateInput is not a valid date
        return '';
      }

      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
      }).format(date);
    };

    return inputString
      .replace('{{sender.first_name}}', reactionData?.sender_details?.first_name || '')
      .replace('{{sender.last_name}}', reactionData?.sender_details?.last_name || '')
      .replace('{{receiver.first_name}}', reactionData?.receiver_details?.first_name || '')
      .replace('{{gift_image}}', reactionData?.product?.product_details?.image_src || '')
      .replace('{{gift_description}}', reactionData?.product?.product_details?.product_description || '')
      .replace('{{gift_message}}', reactionData?.gift_message || '')
      .replace('{{gift_name}}', reactionData?.product?.product_details?.label || '')
      .replace('{{receiver_message}}', reactionData?.response?.msg || '')
      .replace('{{last_accessed}}', formatDate(reactionData?.metaData?.getApp?.last_accessed_at))
      .replace('{{gift_landing}}', reactionData?.content?.imageURL || '');
  }


}
